import WorkindApiClient from "../WorkindApiClient";
import JobCodeMetadata from "./JobCodeMetadata";

class MetadataService extends WorkindApiClient {
  async getJobCode(jobcode: string, country: string): Promise<JobCodeMetadata | undefined> {
    return await this.get<JobCodeMetadata>(`${this.apiUrl}/metadatas/jobcodes/${jobcode}?country=${country}`);
  }
}

const metadataService = new MetadataService();
export default metadataService;
