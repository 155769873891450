import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";

import ImageIcon from "@mui/icons-material/Image";
import ReimboursementProof from "../services/Reimbursement/ReimbursementProof";
import ReimbursementService from "../services/Reimbursement/ReimbursementService";
import { useTranslation } from "react-i18next";

interface StatusContentProps {
  proof: ReimboursementProof;
}

export default function ReimbursmentProofContent(props: StatusContentProps) {
  const { proof } = props;
  const {
    proofId: id,
    proofFilename: fileName,
    proofContentType: contentType,
  } = proof;

  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleProofClick = useCallback(
    async (
      ProofId: string,
      ProofFilename: string,
      ProofContentType: string
    ) => {
      setLoading(true);
      const fileContent: Blob | undefined =
        await ReimbursementService.downloadReimbursementProof(ProofId);
      setLoading(false);
      if (fileContent) {
        const file = fileContent.slice(0, fileContent.size, ProofContentType);
        window.open(URL.createObjectURL(file), "_blank");
      } else {
        console.error(
          "Error downloading proof:",
          "Failed to retrieve the reimbursement proof."
        );
      }
    },
    []
  );

  return (
    <Button
      sx={{
        minWidth: 0,
        padding: "2px",
        width: "28px",
        height: "28px",
        marginLeft: "4px",
        marginRight: "4px",
      }}
      onClick={() => handleProofClick(id, fileName, contentType)}
    >
      <Tooltip
        arrow
        title={
          <Box sx={{ padding: "15px", fontSize: "14px" }}>
            {t("dashboard.userPage.table-file-tooltip", { fileName })}
          </Box>
        }
      >
        <Box height="100%">
          {loading ? (
            <CircularProgress size={22} sx={{ mt: '3px' }} />
          ) : (
            <ImageIcon
              style={{
                verticalAlign: "middle",
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
            />
          )}
        </Box>
      </Tooltip>
    </Button>
  );
}
