import { Palette } from '@mui/material';
import { TypographyVariantsOptions } from '@mui/material/styles';

const typography: TypographyVariantsOptions | ((palette: Palette) => TypographyVariantsOptions) = {
  fontFamily: 'GT-Walsheim, Helvetica, Arial',
  fontSize: 14,
  fontWeightLight: 'normal',
  fontWeightRegular: 'normal',
  fontWeightMedium: 500,
  fontWeightBold: 'bold',
  h1: {
    fontWeight: 500,
    fontSize: '3em',
    lineHeight: 1.167,
    letterSpacing: '-0.01562em',
    '@media (max-width:750px)': {
      fontSize: '2.8em',
    },
  },
  h2: {
    fontWeight: 500,
    fontSize: '2.3em',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em',
    '@media (max-width:750px)': {
      fontSize: '1.4em',
    },
  },
  h3: {
    fontWeight: 500,
    fontSize: '1.8em',
    lineHeight: 1.167,
    letterSpacing: '0em',
    '@media (max-width:750px)': {
      fontSize: '1.35em',
    },
  },
  h4: {
    fontWeight: 500,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em',
  },
  h5: {
    fontWeight: 500,
    fontSize: '1.25em',
    lineHeight: 1.334,
    letterSpacing: '0em',
    '@media (max-width:750px)': {
      fontSize: '1.1em',
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em',
  },
  body1: {
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  body2: {
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
  },
  button: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
  },
  caption: {
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
  overline: {
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase',
  },
};

export default typography;
