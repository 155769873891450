import { Box, Paper, Typography } from "@mui/material";
import Loading from "../../components/Loading";
import { LoadingStatus } from "../../contexts/LoadingStatus";

interface Props {
  children: React.PropsWithChildren<any>;
  title: string;
  error: string;
  loading: LoadingStatus;
}

export default function DashboardCard({ children, title, loading, error }: Props) {
  return (
    <Paper
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: 340,
      }}
    >
      <Typography color="text.secondary" gutterBottom>
        {title}
      </Typography>

      <Box position="relative" height="100%">
        <Loading loading={loading}>
          {error.length > 0 ? (
            <>
              <Typography component="p" color="error.main">
                {error}
              </Typography>
            </>
          ) : (
            <>{children}</>
          )}
        </Loading>
      </Box>
    </Paper>
  );
}
