import { Grid } from "@mui/material";
import * as React from "react";
import ActiveUsers from "./ActiveUsers";
import OrdersSummary from "./OrdersSummary";
import ReimbursementRequestsSummary from "./ReimbursementRequestsSummary";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
export default function Dashboard() {
  const { user } = useSessionContext();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  if (!workindAdmin) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <ActiveUsers />
        </Grid>

        <Grid item xs={12} lg={3}>
          <OrdersSummary />
        </Grid>

        <Grid item xs={12} lg={3}>
          <ReimbursementRequestsSummary />
        </Grid>

        {/* <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: 240,
            }}
          >
            <Chart />
          </Paper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Orders />
          </Paper>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
}
