import WorkindApiClient from "../WorkindApiClient";

class ReimbursementService extends WorkindApiClient {
  async downloadReimbursementProof(id: string): Promise<Blob | undefined> {
    try {
      const result = await this.getBlob(`${this.apiv2Url}/ReimbursementRequest/Proof/${id}`);
      return result;
    } catch {
      return undefined;
    }
  }
}

const reimbursementService = new ReimbursementService();
export default reimbursementService;
