import Me from "../services/Users/Me";
import UserServiceDashboard from "../services/Users/UserDashboardProfile";
import WorkindApiClient from "../services/WorkindApiClient";

class MockUserService extends WorkindApiClient {
  async getMe(): Promise<Me | undefined> {
    return await this.get<Me>(`/mock/me.json`);
  }

  async getUserDashboard(): Promise<UserServiceDashboard | undefined> {
    return await this.get<UserServiceDashboard>(`/mock/dashboard.json`);
  }
}

const mockUserService = new MockUserService();
export default mockUserService;
