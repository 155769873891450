import React, { useState } from "react";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues } from "react-hook-form/dist/types";
import { useSessionContext } from "../../contexts/SessionContext";
import userService from "../../services/Users/UserService";
import { ThemeProvider } from "@emotion/react";
import theme from "../../theme/main-theme";
import { Container } from "@mui/system";
import FooterComponent from "../../userDashboard/components/FooterComponent";
import UserBankDataFormButton from "./UserBankDataFormButton";
import {
  containerStyle,
  formTitleStyle,
  textFieldHelperTextStyle,
  textFieldStyle,
} from "./styles";
import { Notify } from "../../components/notify";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import UserBankConfirmationPopup from "./UserBankConfirmationPopup";
import SecurityMessageBox from "./SecurityMessageBox";

const schema = z.object({
  institution: z
    .string()
    .min(3, { message: "Institution must be at least 3 characters" }),
  transit: z
    .string()
    .min(3, { message: "Transit must be at least 3 characters" }),
  bankAccountNumber: z
    .string()
    .min(6, { message: "Account number must be at least 6 characters" }),
});

type FormData = z.infer<typeof schema>;

export default function UserBankDataForm() {
  const { user, organizationHasFeature } = useSessionContext();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({ resolver: zodResolver(schema) });
  const { t } = useTranslation();

  const onSubmit = async (userBankData: FieldValues) => {
    if (!user) return;
    try {
      const userId = user?.userId;
      const organizationId = user?.user.organizationId.toString();
      await userService.addUserBankData(organizationId, userId, userBankData);

      setPopupOpen(true);
    } catch (error: any) {
      Notify.error(t("user.bank-information.error-submit"));
    }
  };

  const returnUrl = `${process.env.REACT_APP_PORTAL_URL}/account`;

  if (
    !user ||
    !user.user ||
    !organizationHasFeature(
      user.user.organizationId,
      OrganizationFeature.BankReimbursement
    )
  ) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <UserBankConfirmationPopup
        onOkUrl={returnUrl}
        onCloseUrl={returnUrl}
        show={isPopupOpen}
      />
      <Container maxWidth="lg">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          padding={containerStyle.padding}
        >
          <Typography variant="h6" gutterBottom style={formTitleStyle}>
            {t("user.bank-information.form-label")}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                fullWidth
                {...register("institution")}
                error={!!errors.institution}
                helperText={t("user.bank-information.institution")}
                InputProps={textFieldStyle}
                FormHelperTextProps={textFieldHelperTextStyle}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                fullWidth
                {...register("transit")}
                error={!!errors.transit}
                helperText={t("user.bank-information.transit")}
                InputProps={textFieldStyle}
                FormHelperTextProps={textFieldHelperTextStyle}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                fullWidth
                {...register("bankAccountNumber")}
                error={!!errors.bankAccountNumber}
                helperText={t("user.bank-information.account-number")}
                InputProps={textFieldStyle}
                FormHelperTextProps={textFieldHelperTextStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <SecurityMessageBox
                label={t("user.bank-information.security-info")}
                mt={6}
                marginTop={0}
                marginBottom={4}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Box textAlign={{ xs: "center", md: "left" }}>
                <UserBankDataFormButton
                  type="submit"
                  label={t("user.bank-information.form-submit")}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={4}>
              <Box textAlign="left">
                <UserBankDataFormButton
                  type="button"
                  label={t("user.bank-information.form-cancel")}
                  onclick={() => window.open(returnUrl, "_parent")}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <FooterComponent />
    </ThemeProvider>
  );
}
