import { Grid, Paper } from "@mui/material";

interface Props {
  children: React.PropsWithChildren<any>;
}

export default function PageToolbar({ children }: Props) {
  return (
    <Paper elevation={2} sx={{ padding: "10px 10px", marginBottom: "20px" }}>
      <Grid item>{children}</Grid>
    </Paper>
  );
}
