import {
  FilledInputProps,
  FormHelperTextProps,
  InputProps,
  OutlinedInputProps,
} from "@mui/material";
import theme from "../../theme/main-theme";
import React from "react";

export const textFieldStyle:
  | Partial<FilledInputProps>
  | Partial<OutlinedInputProps>
  | Partial<InputProps> = {
  style: {
    borderRadius: "10px",
    height: "45px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #C8C8C8",
    fontWeight: "normal",
    color: theme.palette.getContrastText(theme.palette.background.paper),
  },
};

export const textFieldHelperTextStyle: Partial<FormHelperTextProps<"p">> = {
  style: {
    marginLeft: "0",
    color: "#00334A",
    font: 'normal normal normal 16px/18px GT Walsheim',
    letterSpacing: "-0.48px",
    opacity: '1'
  },
};

export const containerStyle = {
  padding: { xs: "10px 4px", sm: "50px 20px", md: "50px 40px" },
};

export const formTitleStyle = {
  marginBottom: "30px",
  fontSize: "35px",
  font: 'normal normal medium 51px/58px GT Walsheim Pro',
  color: "#00334A",
  opacity: '1'
};

export const formSecurityInfoTextStyle: React.CSSProperties = {
  font: 'normal normal medium 16px/20px GT Walsheim',
  lineHeight: "1",
  letterSpacing: "-0.48px",
  color: "#00334A",
  maxWidth: "191px",
  textAlign: "left",
};
