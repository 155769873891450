import { Link, ListItemButton, ListItemIcon } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link as RouterLink } from "react-router-dom";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { useTranslation } from "react-i18next";
import SummarizeIcon from "@mui/icons-material/Summarize";
import OrganizationFeature from "../../services/Organizations/OrganizationFeature";
import PeopleOutlineIcon from "@mui/icons-material/PeopleAltOutlined";

export default function LayoutMenu() {
  const { t } = useTranslation();
  const { role, hasFeature } = useSessionContext();

  const hasFeatureEmployeeProfile = hasFeature(OrganizationFeature.EmployeeProfile);

  const workindAdmin = RolesHelpers.IsWorkindAdmin(role);
  const organizationAdmin = RolesHelpers.IsOrganizationAdmin(role);

  return (
    <>
      {workindAdmin && (
        <ListItemButton>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <Link component={RouterLink} to="/" underline="none">
            {t("admin.menu.dashboard")}
          </Link>
        </ListItemButton>
      )}
      {workindAdmin && (
        <>
          <ListItemButton>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <Link component={RouterLink} to="/admin/orders" underline="none">
              {t("admin.menu.orders")}
            </Link>
          </ListItemButton>

          <ListItemButton>
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <Link component={RouterLink} to="/admin/employee-reports" underline="none">
              {t("admin.menu.payrollReport")}
            </Link>
          </ListItemButton>

          <ListItemButton>
            <ListItemIcon>
              <SummarizeIcon />
            </ListItemIcon>
            <Link component={RouterLink} to="/admin/transaction-reports" underline="none">
              {t("admin.menu.transactionReport")}
            </Link>
          </ListItemButton>
        </>
      )}
      {organizationAdmin && (
        <>
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <Link component={RouterLink} to="/admin/users" underline="none">
              {t("admin.menu.users")}
            </Link>
          </ListItemButton>
          {hasFeatureEmployeeProfile && (
            <ListItemButton>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
              <Link component={RouterLink} to="/admin/employee-profiles" underline="none">
                {t("admin.menu.employeeProfiles")}
              </Link>
            </ListItemButton>
          )}
        </>
      )}
      {/* 
      <ListItemButton>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton> */}
      {/* <Divider sx={{ my: 1 }} />
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton> */}
    </>
  );
}
