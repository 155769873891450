import { useCallback, useEffect, useMemo, useRef } from "react";
import { Box, CardMedia, Grid, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SwiperSlide } from "swiper/react";
import SwiperEl from "swiper";

import userService from "../../services/Users/UserService";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import Slideshow from "../../components/Slideshow";
import FormatHelpers from "../../services/FormatHelpers";
import eyeIcon from "../../assets/images/eye.svg";
import trashIcon from "../../assets/images/trash.svg";
import bagIcon from "../../assets/images/bag.svg";

type Props = {
  state: UserServiceDashboard | undefined;
};
export function OrdersComponent(props: Props) {
  const lang = localStorage.getItem("language") || "en";
  const { t } = useTranslation();

  const { orders = [] } = props.state || {};
  const { length: ordersLength } = orders;

  const buttons = useMemo(
    () => [
      {
        href: `${userService.appPortalUrl}/my-services`,
        target: "_parent",
        icon: eyeIcon,
        iconAlt: "Eye icon",
        label: t("dashboard.userPage.link-order-details"),
        shortLabel: t("common.view"),
      },
      {
        href: `${userService.appPortalUrl}/contact`,
        target: "_parent",
        icon: trashIcon,
        iconAlt: "Trash icon",
        label: t("dashboard.userPage.link-order-cancel"),
        shortLabel: t("common.cancel"),
      },
    ],
    [t]
  );

  const theme = useTheme();
  const isUnderLargeWidth = useMediaQuery(theme.breakpoints.down("lg"));
  const isUnderMediumWidth = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef<SwiperEl>();
  const onSwiperInit = useCallback((swiper: SwiperEl) => (swiperRef.current = swiper), []);

  useEffect(() => {
    if (swiperRef.current && isUnderMediumWidth) {
      swiperRef.current.slides.forEach((e: HTMLElement) => e.style.setProperty("width", "auto"));
    }
  }, [isUnderMediumWidth]);

  const headingStyle = useMemo(
    () => ({
      fontSize: "20px",
      lineHeight: "25px",
      letterSpacing: "-0.6px",
      marginBottom: "10px",
      color: "#02334A",
      fontWeight: "500",
    }),
    []
  );

  const bodyStyle = useMemo(
    () => ({
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "-0.4px",
      color: "#000000",
      fontWeight: "400",
    }),
    []
  );

  return (
    <Box mb={7}>
      <Typography variant="h3">{t("dashboard.userPage.latest-orders-label")}</Typography>
      <Box>
        {ordersLength > 0 ? (
          <Slideshow
            spaceBetween={isUnderMediumWidth ? 30 : 40}
            slidesPerView={isUnderMediumWidth ? "auto" : isUnderLargeWidth ? 2 : 1}
            totalSlides={ordersLength}
            onInit={onSwiperInit}
          >
            {orders.map((order) => (
              <SwiperSlide key={order.id}>
                <Paper
                  elevation={0}
                  sx={{ position: "relative", overflow: "hidden", mb: "6px", width: { xs: ordersLength > 1 ? "268px" : "100%", sm: "268px", md: "100%" } }}
                >
                  <Grid container columns={20} mt={0} sx={{ padding: { xs: 0, lg: "28px 42px" } }}>
                    <Grid item xs={20} lg={3} sx={{ borderRight: { xs: 0, lg: 1 }, padding: { xs: "28px 28px 0", lg: 0 } }}>
                      <Box sx={{ borderBottom: { xs: "1px solid rgba(112,112,112,0.37)", lg: 0 }, paddingBottom: { xs: 2, lg: 0 } }}>
                        <Typography sx={{ ...headingStyle, marginBottom: { xs: "2px", lg: "10px" } }}>{t("dashboard.userPage.table-order")}</Typography>
                        <Typography sx={bodyStyle}>{`#${order.id}`}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={20} lg={8} sx={{ borderRight: { xs: 0, lg: 1 }, padding: { xs: "0 28px 28px", lg: "0 0 0 6" } }}>
                      <Box sx={{ marginTop: { xs: 2, lg: 0 } }}>
                        <Typography sx={{ ...headingStyle, marginBottom: { xs: "2px", lg: "10px" } }}>
                          {isUnderLargeWidth ? t("dashboard.userPage.table-date") : t("dashboard.userPage.table-order-date")}
                        </Typography>
                        <span title={FormatHelpers.formatDateTimeShort(new Date(order.date))}>
                          <Typography sx={bodyStyle}>
                            {lang === "fr" && FormatHelpers.formatDateDescriptiveFr(new Date(order.date))}
                            {lang === "en" && FormatHelpers.formatDateDescriptive(new Date(order.date))}
                          </Typography>
                        </span>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      lg={4}
                      sx={{
                        position: { xs: "absolute", lg: "static" },
                        paddingLeft: { xs: 0, lg: 6 },
                        top: "34px",
                        right: "28px",
                        textAlign: { xs: "right", lg: "left" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "11px", lg: "20px" },
                          lineHeight: { xs: "14px", lg: "25px" },
                          letterSpacing: { xs: "-0.28px", lg: "-0.6px" },
                          color: { xs: "#00A98F", lg: "#02334A" },
                          marginBottom: { xs: 0, lg: "10px" },
                          fontWeight: { xs: 400, lg: 500 },
                        }}
                      >
                        {t("dashboard.userPage.table-amount")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "22px", lg: "29px" },
                          lineHeight: { xs: "27px", lg: "36px" },
                          letterSpacing: { xs: "-0.55px", lg: "-0.72px" },
                          color: "#000000",
                          fontWeight: "400",
                        }}
                      >
                        {FormatHelpers.formatMoneyLocale(order.total, lang)}
                      </Typography>
                    </Grid>
                    <Grid item xs={20} lg={5} sx={{ paddingRight: { xs: 0, lg: 2 } }}>
                      <Grid container>
                        {buttons.map(({ href, target, icon, iconAlt, label, shortLabel }, index) => (
                          <Grid
                            item
                            xs={6}
                            lg={12}
                            key={`button-${index}`}
                            borderBottom={{ xs: 0, lg: 0 }}
                            sx={{ backgroundColor: { xs: "#EDEDED", lg: "transparent" }, margin: { lg: "2px" } }}
                            borderRight={{ xs: index === 0 ? "1px solid white" : 0, lg: 0 }}
                          >
                            <Button
                              sx={{
                                textTransform: "none",
                                padding: { xs: "15px", lg: "10px" },
                                width: "100%",
                                height: { xs: "100%", lg: "auto" },
                                alignItems: "center",
                                justifyContent: { xs: "center", lg: "flex-start" },
                              }}
                              href={href}
                              target={target}
                            >
                              <img src={icon} alt={iconAlt} />
                              <Typography
                                sx={{
                                  fontSize: { xs: "12px", lg: "18px" },
                                  lineHeight: { xs: "15px", lg: "23px" },
                                  letterSpacing: { xs: "-0.3px", lg: "-0.54px" },
                                  color: "#000000",
                                  fontWeight: { xs: "400", lg: "500" },
                                }}
                                ml={{ xs: 1.5, lg: 2 }}
                                mt="2px"
                              >
                                {isUnderLargeWidth ? shortLabel : label}
                              </Typography>
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </SwiperSlide>
            ))}
          </Slideshow>
        ) : (
          <Paper elevation={0} sx={{ padding: { xs: "65px 15px", md: "34px 15px" }, marginBottom: 6 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <CardMedia component="img" src={bagIcon} sx={{ mb: "5px", width: { xs: 30, md: 40 } }} />
              <Typography sx={{ fontSize: { xs: "19px", md: "27px" }, fontWeight: "400", textAlign: "center" }}>
                {t("dashboard.userPage.table-nodateorder")}
              </Typography>
              <Typography>
                <Link href={`${userService.appPortalUrl}/home`} target="_parent" color="#26A68F" letterSpacing={-0.4}>
                  {t("dashboard.userPage.order-now-label")}
                </Link>
              </Typography>
            </Box>
          </Paper>
        )}
      </Box>
    </Box>
  );
}
