import React from "react";
import { Stack, Typography } from "@mui/material";
import { formSecurityInfoTextStyle } from "./styles";

interface Props {
  label: string;
  mt?: number;
  marginTop?: number;
  marginBottom?: number;
}

export default function SecurityMessageBox({ label, mt, marginTop, marginBottom }: Props) {
  return (
    <Stack
      direction={"row"}
      mt={mt}
      alignItems="center"
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <img src="/images/secured.svg" alt="Secured" width="75" height="38" />
      <Typography ml={2} style={formSecurityInfoTextStyle}>
        {label}
      </Typography>
    </Stack>
  );
}
