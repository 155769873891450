import WorkindApiClient from "../WorkindApiClient";
import OrderDetailled from "./OrderDetailled";

class OrderService extends WorkindApiClient {
  async getOrders(organizationId: number | undefined, dateStart: string | undefined = undefined, dateEnd: string | undefined = undefined): Promise<Array<OrderDetailled>> {
    let orders: Array<OrderDetailled> | undefined = [];
    if (!dateStart) {
      orders = await this.get<Array<OrderDetailled>>(`${this.apiUrl}/organizations/${organizationId}/orders`);
    } else {
      orders = await this.get<Array<OrderDetailled>>(`${this.apiUrl}/organizations/${organizationId}/orders?dateStart=${dateStart}&dateEnd=${dateEnd}`);
    }
    if (!orders) return [];

    for (let order of orders) {
      order.date = new Date(order.date);
    }
    return orders;
  }
}

const orderService = new OrderService();
export default orderService;
