import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import SecurityMessageBox from "./SecurityMessageBox";
import React from "react";

export default function UserBankConfirmationPopup({
  show,
  onOkUrl,
  onCloseUrl,
}: {
  show: boolean;
  onOkUrl: string;
  onCloseUrl: string;
}) {
  const { t } = useTranslation();

  return (
    <Dialog open={show} maxWidth="md">
      <Box p={4}>
        <DialogTitle
          color="#00334A"
          sx={{ m: 0, p: 0, fontSize: "32px", fontWeight: "500" }}
        >
          {t("user.bank-information-confirmation.title")}
        </DialogTitle>

        <IconButton
          aria-label="close"
          href={onCloseUrl}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: "#fff",
            bgcolor: "#26A68F",
            "&:hover": {
              bgcolor: "#2ab99f",
            },
          }}
        >
          <CloseIcon sx={{ width: "16px", height: "16px" }} />
        </IconButton>

        <Divider />

        <DialogContent sx={{ p: 0, pt: 0, pb: 2 }}>
          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} lg={8} alignItems="center" alignContent="center">
              <Typography variant="h3" color="#00334A">
                {t("user.bank-information-confirmation.description")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              alignContent="center"
              alignItems="center"
              textAlign="center"
            >
              <img
                src="/images/confirmation.svg"
                alt="Secured"
                width="120"
                height="120"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} mt={3}>
            <Grid item xs={12} lg={8} alignItems="center" alignContent="center">
              <Button
                variant="contained"
                href={onOkUrl}
                target="_parent"
                sx={{ backgroundColor: "#26A68F", textAlign: "center" }}
              >
                {t("user.bank-information-confirmation.ok")}
              </Button>{" "}
            </Grid>
            <Grid
              item
              xs={12}
              lg={4}
              alignContent="center"
              alignItems="center"
              textAlign="center"
            >
              <SecurityMessageBox
                label={t("user.bank-information.security-info")}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
