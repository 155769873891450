import { Avatar, IconButton, Typography } from "@mui/material";
import PrimaryText from "../../theme/typography/primary-text";
import PersonIcon from "@mui/icons-material/Person";
import { useSessionContext } from "../../contexts/SessionContext";

export default function UserAccount() {
  const { user } = useSessionContext();

  if (!user) return <></>;

  return (
    <IconButton color="inherit">
      <Avatar style={{ marginRight: "14px" }}>
        <PersonIcon />
      </Avatar>

      <Typography color={PrimaryText}>{user.email}</Typography>
    </IconButton>
  );
}
