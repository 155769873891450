import WorkindApiClient from "../WorkindApiClient";
import AnalyticsKeyedValue from "./AnalyticsKeyedValue";

class AnalyticsService extends WorkindApiClient {
  async getActiveUsers(): Promise<Array<AnalyticsKeyedValue> | undefined> {
    return await this.get<Array<AnalyticsKeyedValue>>(`${this.apiUrl}/users/me/analytics/activeusers`);
  }

  async getOrdersSummary(): Promise<Array<AnalyticsKeyedValue> | undefined> {
    return await this.get<Array<AnalyticsKeyedValue>>(`${this.apiUrl}/users/me/analytics/orderssummary`);
  }

  async getReimbursementRequestsSummary(): Promise<Array<AnalyticsKeyedValue> | undefined> {
    return await this.get<Array<AnalyticsKeyedValue>>(`${this.apiUrl}/users/me/analytics/reimbursementrequestssummary`);
  }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
