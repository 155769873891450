import WorkindApiClient from "../WorkindApiClient";
import { CreateTransactionType } from "./CreateTransactionType";
import TransactionWithAccount from "./TransactionWithAccount";
import dayjs from "dayjs";

class TransactionService extends WorkindApiClient {
  async getTransactions(organizationId: number, userId: number): Promise<Array<TransactionWithAccount>> {
    const list = await this.get<Array<TransactionWithAccount>>(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/transactions`);
    if (!list) return [];

    let mapped = list.map((t): TransactionWithAccount => {
      return {
        ...t,
        date: t.date ? dayjs(t.date) : undefined,
        organizationWalletId: t.organizationWalletId ?? "",
      };
    });

    let balance = 0;
    for (let i = mapped.length - 1; i >= 0; --i) {
      balance = balance + mapped[i].amount;
      mapped[i].balance = balance;
    }

    return mapped;
  }

  async createTransaction(
    organizationId: number,
    userId: number,
    organizationWalletId: string,
    type: CreateTransactionType,
    description: string,
    amount: number
  ): Promise<boolean> {
    const typeNumber = parseInt(CreateTransactionType[type]);

    const data = {
      type: typeNumber,
      organizationWalletId: organizationWalletId,
      amount: amount,
      description: description,
    };
    return await this.postNoResponse(`${this.apiUrl}/organizations/${organizationId}/users/${userId}/transactions`, data);
  }
}

const transactionService = new TransactionService();
export default transactionService;
