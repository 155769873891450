import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import analyticsService from "../../services/Analytics/AnalyticsService";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import AnalyticsKeyedValue from "../../services/Analytics/AnalyticsKeyedValue";
import FormatHelpers from "../../services/FormatHelpers";
import { Link, Table, TableBody, TableCell, TableRow } from "@mui/material";
import DashboardCard from "./DashboardCard";
import { Link as RouterLink } from "react-router-dom";

export default function ActiveUsers() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [keyedValues, setKeyedValues] = useState<Array<AnalyticsKeyedValue>>([]);

  useEffect(() => {
    async function get() {
      try {
        if (user && workindAdmin) {
          setLoading(LoadingStatus.Loading);

          const activeUsersByOrganization = await analyticsService.getActiveUsers();
          setKeyedValues(activeUsersByOrganization?.slice(0, 5) || []);

          setLoading(user ? LoadingStatus.Success : LoadingStatus.Failure);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        setErrorMessage(t("dashboard.error-loading"));
      }
    }

    get();
  }, [t, user, workindAdmin]);

  const total = FormatHelpers.formatInt(keyedValues.reduce((accumulator, o) => accumulator + o.value, 0));

  return (
    <DashboardCard title={t("dashboard.active-users.title")} loading={loading} error={errorMessage}>
      <Typography component="p" variant="h4">
        {total}
      </Typography>{" "}
      {keyedValues.length > 1 && <ActiveUsersTable rows={keyedValues} />}
      <Link component={RouterLink} to="/admin/users" underline="none" position="absolute" bottom="0">
        {t("dashboard.active-users.manage-users")}
      </Link>
    </DashboardCard>
  );
}

function ActiveUsersTable({ rows }: { rows: Array<AnalyticsKeyedValue> }) {
  return (
    <Table size="small" sx={{ marginTop: "10px" }}>
      {/* <TableHead>
          <TableRow>
            <TableCell>{t("dashboard.organization")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead> */}
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {row.label}
            </TableCell>
            <TableCell align="right">{row.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
