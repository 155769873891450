import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../contexts/SessionContext";

type Props = {
  allowAllSelection: boolean;
  value: number;
  onChange: (id: number) => void;
};

export default function OrganizationSelector(props: Props) {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const handleOrganizationChange = (event: SelectChangeEvent) => {
    const id = parseInt(event.target.value);
    props.onChange(id);
  };

  if (!user) {
    return <></>;
  }

  return (
    <FormControl sx={{ minWidth: 300 }}>
      <InputLabel id="organization-label">{t("common.organization")}</InputLabel>
      <Select labelId="organization-label" id="organization-select" value={props.value.toString()} label={t("common.organization")} onChange={handleOrganizationChange} autoWidth>
        <MenuItem value={-1}>{props.allowAllSelection ? "All" : ""}</MenuItem>

        {user.organizations.map((organization) => {
          return (
            <MenuItem key={organization.id} value={organization.id.toString()}>
              {organization.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
