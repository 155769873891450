import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./admin/layout/Layout";
import Dashboard from "./admin/dashboard/Dasboard";
import OrdersPage from "./admin/pages/OrdersPage";
import { useSessionContext } from "./contexts/SessionContext";
import { useEffect, useState } from "react";
import LoadingComponent from "./components/LoadingComponent";
import RolesHelpers from "./services/Users/RolesHelpers";
import UsersPage from "./admin/pages/UsersPage";
import EmployeeProfilePage from "./admin/pages/EmployeeProfilePage";
import EmployeeProfilesPage from "./admin/pages/EmployeeProfilesPage";
import Unauthorized from "./components/Unauthorized";
import { EmployeeReporsPage } from "./admin/pages/EmployeeReportsPage";
import UserDashboard from "./userDashboard/userDashboard";
import UserProfilePage from "./admin/pages/UserProfilePage";
import { TransactionReportPage } from "./admin/pages/TransactionReportPage";
import { UserBankDataForm } from "./userBankData";

function App() {
  const { user, role } = useSessionContext();
  const [hasTimeout, setHasTimeout] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!user) {
        setHasTimeout(true);
      }
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [user]);

  if (!user) {
    if (hasTimeout) return <Unauthorized />;
    else return <LoadingComponent />;
  }

  return (
    <>
      {pathname.includes("/user") && (
        <Routes>
          <Route path="/user" element={<UserDashboard />}></Route>
          <Route path="/user/bank" element={<UserBankDataForm />}></Route>
        </Routes>
      )}
      {pathname.includes("/admin") && (
        <Layout>
          <Routes>
            {RolesHelpers.IsOrganizationAdmin(role) && (
              <>
                <Route path="/admin" element={<Dashboard />}></Route>
                <Route path="/admin/orders" element={<OrdersPage />}></Route>
                <Route path="/admin/users" element={<UsersPage />}></Route>
                <Route path="/admin/users/:organizationId?" element={<UsersPage />}></Route>
                <Route path="/admin/users/:organizationId/:idUser" element={<UserProfilePage />}></Route>
                <Route path="/admin/employee-profiles/:organizationId?" element={<EmployeeProfilesPage />}></Route>
                <Route path="/admin/employee-profiles/:organizationId/:id/:userId" element={<EmployeeProfilePage />}></Route>
                <Route path="/admin/employee-reports/:organizationId?" element={<EmployeeReporsPage />}></Route>
                <Route path="/admin/transaction-reports/:organizationId?" element={<TransactionReportPage />}></Route>
              </>
            )}
          </Routes>
        </Layout>
      )}
    </>
  );
}

export default App;
