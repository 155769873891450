import { useEffect, useState } from "react";
import { Notify } from "../../components/notify";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import FormatHelpers from "../../services/FormatHelpers";
import userService from "../../services/Users/UserService";
import EmployeeProfile from "../../services/Users/EmployeeProfile";
import { useNavigate, useParams } from "react-router-dom";
import Unauthorized from "../../components/Unauthorized";
import OrganizationSelector from "../../components/OrganizationSelector";
import { Button, Typography } from "@mui/material";

export default function EmployeeProfilesPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const { organizationId } = useParams<string>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const [users, setUsers] = useState<Array<EmployeeProfile>>([]);

  const [organizationSelected, setOrganizationSelected] = useState<number>(organizationId ? parseInt(organizationId) : -1);

  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);
  const orgAdmin = user && RolesHelpers.IsOrganizationAdminFromList(user.roles);

  useEffect(() => {
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0].id);
      console.log(`selected first org '${user.organizations[0].id}'`);
    }
  }, [user, workindAdmin]);

  useEffect(() => {
    async function get() {
      try {
        if (organizationSelected >= 0) {
          setLoading(LoadingStatus.Loading);
          const users = await userService.getEmployeeProfilesOfOrganization(organizationSelected);
          console.log(`EmployeeProfilesPage.useEffet(orgId=${organizationSelected}) --> ${users.length}`);
          setUsers(users);
          setLoading(LoadingStatus.Success);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("admin.users.error-loading"));
      }
    }

    get();
  }, [user, organizationSelected]);

  if (!orgAdmin) {
    return <Unauthorized />;
  }

  const columns: GridColDef[] = [
    {
      field: "action",
      headerName: "",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e: any) => {
          e.stopPropagation(); // don't select this row after clicking
          const row = params.row;
          navigate(`/admin/employee-profiles/${organizationSelected}/${row.id}/${row.userId}`);
        };

        return (
          <Button variant="contained" color="primary" size="small" onClick={onClick}>
            {t("common.detail")}
          </Button>
        );
      },
    },
    {
      field: "name",
      headerName: t("common.user"),
      type: "string",
      minWidth: 200,
      valueGetter: ({ row }) => {
        return row.firstname + (row.lastname ? " " + row.lastname : "");
      },
    },
    { field: "email", headerName: t("common.email"), type: "string", minWidth: 200 },
    {
      field: "allocation",
      headerName: "Allocation",
      type: "number",
      disableExport: true,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatMoney(value);
      },
    },
    {
      field: "allocation_raw",
      headerName: "Allocation",
      type: "number",
      disableExport: true,
      valueGetter: ({ row }) => {
        return row.allocation;
      },
    },
    { field: "employeeNumber", headerName: t("user.field-employeeNumber"), type: "string" },
    { field: "companyCode", headerName: t("user.field-companyCode"), type: "string", minWidth: 130 },
    { field: "division", headerName: t("user.field-division"), type: "string" },
    { field: "employeeType", headerName: t("user.field-employeeType"), type: "string" },
    {
      field: "employeeTypeDate",
      headerName: t("user.field-employeeTypeDate"),
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return value.format("YYYY/MM/DD");
      },
    },
    { field: "jobCountry", headerName: t("user.field-jobCountry"), type: "string" },
    { field: "personalLocation", headerName: t("user.field-personalLocation"), type: "string" },
    { field: "status", headerName: t("user.field-status"), type: "string" },
    {
      field: "statusDate",
      headerName: t("user.field-statusDate"),
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return value.format("YYYY/MM/DD");
      },
    },
    { field: "payType", headerName: t("user.field-payType"), type: "string" },
    {
      field: "payTypeDate",
      headerName: t("user.field-payTypeDate"),
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return value.format("YYYY/MM/DD");
      },
    },
    { field: "jobCode", headerName: t("user.field-employeeType"), type: "string" },
    {
      field: "jobCodeDate",
      headerName: t("user.field-jobCodeDate"),
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return value.format("YYYY/MM/DD");
      },
    },
    { field: "payGroup", headerName: t("user.field-employeeType"), type: "string" },
    {
      field: "hireDate",
      headerName: t("user.field-hireDate"),
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return value.format("YYYY/MM/DD");
      },
    },
  ];

  if (!orgAdmin) {
    return <Unauthorized />;
  }
  if (!user || loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  function CustomToolbar() {
    let fileName = "employeeProfiles";

    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem
          options={{
            fileName: fileName,
            fields: [
              "name",
              "email",
              "allocation_raw",
              "employeeNumber",
              "companyCode",
              "division",
              "employeeType",
              "employeeTypeDate",
              "jobCountry",
              "personalLocation",
              "status",
              "statusDate",
              "payType",
              "payTypeDate",
              "jobCode",
              "jobCodeDate",
              "payGroup",
              "hireDate",
            ],
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("admin.employee-profiles.title")}</Typography>
      </Stack>
      {workindAdmin && (
        <Stack direction="row" alignItems="center" spacing={1} mb={3}>
          {workindAdmin && <OrganizationSelector allowAllSelection={false} onChange={(id) => setOrganizationSelected(id)} value={organizationSelected} />}
        </Stack>
      )}

      <DataGrid
        rows={users}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              allocation_raw: false,
            },
          },
          pagination: { paginationModel: { pageSize: 15 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[15]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
