const PrimaryText = {
  main: '#00334A',
  800: '#00334A',
  600: '#00334A',
  400: '#00334A',
  200: '#00334A',
  100: '#00334A',
};

export default PrimaryText;
