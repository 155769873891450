import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";

interface Props {
  url: string;
}

export default function BackButton(props: Props) {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(props.url)}>
      <ArrowBackIosNewIcon />
    </Button>
  );
}
