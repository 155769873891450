import React from "react";
import { Button } from "@mui/material";

interface Props {
  type: "button" | "submit" | "reset";
  label: string;
  onclick?: () => void;
}

export default function UserBankDataFormButton({
  type,
  label,
  onclick,
}: Props) {
  return (
    <Button
      fullWidth
      type={type}
      variant="contained"
      onClick={onclick}
    >
      {label}
    </Button>
  );
}
