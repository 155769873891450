import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { SwiperSlide } from "swiper/react";
import SwiperEl from "swiper";

import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import Slideshow from "../../components/Slideshow";
import parse from "html-react-parser";

type Props = {
  state: UserServiceDashboard | undefined;
};

export default function NewsComponent(props: Props) {
  const lang = localStorage.getItem("language") || "en";
  const { t } = useTranslation();

  const { news = [] } = props.state || {};
  const { length: newsLength } = news;

  const theme = useTheme();
  const isUnderLargeWidth = useMediaQuery(theme.breakpoints.down("lg"));
  const isUnderMediumWidth = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef<SwiperEl>();
  const onSwiperInit = useCallback((swiper: SwiperEl) => (swiperRef.current = swiper), []);

  useEffect(() => {
    if (swiperRef.current && isUnderMediumWidth) {
      swiperRef.current.slides.forEach((e: HTMLElement) => e.style.setProperty("width", "auto"));
    }
  }, [isUnderMediumWidth]);

  return newsLength > 0 ? (
    <Box mb={8} mt={-8}>
      <Typography variant="h3" mb={1.75} sx={{ display: { xs: "none", md: "block" } }}>
        {t("dashboard.userPage.latest-news-label")}
      </Typography>
      <Slideshow
        spaceBetween={isUnderMediumWidth ? 30 : 40}
        slidesPerView={isUnderMediumWidth ? "auto" : isUnderLargeWidth ? 3 : 2}
        totalSlides={newsLength}
        onInit={onSwiperInit}
        loop
      >
        {news.map((row) => (
          <SwiperSlide key={row.id}>
            <Link href={row.url ? (lang === "fr" ? row.url.fr : row.url.en) : undefined} sx={{ textDecoration: "none" }}>
              <Paper elevation={0} sx={{ overflow: "hidden", boxShadow: "0 5px 15px rgba(0,0,0,0.15)", margin: "20px 0" }}>
                <Box sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, width: { xs: "268px", md: "100%" } }}>
                  <CardMedia
                    component="img"
                    sx={{ width: { xs: "100%", lg: "52%" }, height: { xs: "165px", lg: "324px" }, flexShrink: 0 }}
                    image={row.image.en}
                  />
                  <Box
                    sx={{
                      padding: { xs: "30px 15px 10px", lg: "36px 40px" },
                      height: { xs: "176px", lg: "auto" },
                    }}
                  >
                    <Typography variant="h4" sx={{ color: "#00334a", marginBottom: "15px" }}>
                      {lang === "fr" && row.title.fr}
                      {lang === "en" && row.title.en}
                    </Typography>
                    <Typography
                      sx={{
                        whiteSpace: "i",
                        marginTop: "15px",
                        fontSize: "16px",
                        letterSpacing: "-0.4px",
                        lineHeight: "20px",
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitLineClamp: { xs: 3, md: 4, lg: 8 },
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {lang === "fr" && parse(row.text.fr)}
                      {lang === "en" && parse(row.text.en)}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Link>
          </SwiperSlide>
        ))}
      </Slideshow>
    </Box>
  ) : null;
}
