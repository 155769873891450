import { useCallback, useEffect, useRef } from "react";
import {
  Box,
  CardMedia,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SwiperSlide } from "swiper/react";
import SwiperEl from "swiper";

import Slideshow from "../../components/Slideshow";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import InfoIcon from "../../components/InfoIcon";
import FormatHelpers from "../../services/FormatHelpers";

type Props = {
  state: UserServiceDashboard | undefined;
};
export function OrderAgainComponent(props: Props) {
  const lang = localStorage.getItem("language") || "en";
  const { t } = useTranslation();

  const { orderProducts = [] } = props.state || {};
  const { length: productsLength } = orderProducts;

  const theme = useTheme();
  const isUnderLargeWidth = useMediaQuery(theme.breakpoints.down("lg"));
  const isUnderMediumWidth = useMediaQuery(theme.breakpoints.down("md"));

  const swiperRef = useRef<SwiperEl>();
  const onSwiperInit = useCallback(
    (swiper: SwiperEl) => (swiperRef.current = swiper),
    []
  );

  useEffect(() => {
    if (swiperRef.current && isUnderLargeWidth) {
      swiperRef.current.slides.forEach((e: HTMLElement) =>
        e.style.setProperty("width", "auto")
      );
    }
  }, [isUnderLargeWidth]);

  if (productsLength === 0) {
    return null;
  }

  return (
    <Box mb={5.25}>
      <Typography variant="h3">
        {t("dashboard.userPage.order-again-label")}
      </Typography>
      {/* {productsLength === 0 && (
            <Paper elevation={0}>
              <Typography sx={{ fontSize: "1.4rem", fontWeight: "400", padding: "63px 0", textAlign: "center" }}>
                {t("dashboard.userPage.table-nodateorder")}
              </Typography>
            </Paper>
          )} */}
      <Box mt="-20px">
        <Slideshow
          spaceBetween={isUnderMediumWidth ? 30 : 40}
          slidesPerView={isUnderLargeWidth ? "auto" : 3}
          totalSlides={productsLength}
          onInit={onSwiperInit}
        >
          {orderProducts.map((product) => {
            const {
              id,
              url = null,
              imageUrl,
              title,
              price,
              regularPrice,
              description,
            } = product;
            const hasUrl = url !== null;
            const titleLocale = lang === "fr" ? title.fr : title.en;
            const descriptionLocale =
              lang === "fr" ? description.fr : description.en;
            return (
              <SwiperSlide key={id}>
                <Link
                  href={hasUrl ? (lang === "fr" ? url.fr : url.en) : undefined}
                  target="_parent"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    height: "100%",
                    alignItems: "stretch",
                  }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      width: { xs: "268px", md: "384px" },
                      boxShadow: "0 0 20px rgba(0,0,0,0.25)",
                      margin: "20px 0",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column" }}
                      height="100%"
                    >
                      <CardMedia
                        component="img"
                        sx={{ width: "100%", height: "178px" }}
                        image={imageUrl}
                      />
                      <Box
                        display="flex"
                        flexDirection="column"
                        flexGrow={1}
                        sx={{
                          padding: "20px 30px 30px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#00334a",
                            marginBottom: 0,
                            lineHeight: "1",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                          title={titleLocale}
                        >
                          {titleLocale}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            letterSpacing: "-0.38px",
                            lineHeight: "19px",
                          }}
                        >
                          {`${t(
                            "dashboard.userPage.from-price-label"
                          )} ${FormatHelpers.formatMoneyLocale(price, lang)}`}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            letterSpacing: "-0.38px",
                            lineHeight: "19px",
                            color: "#A8A8A8",
                            textDecoration: "line-through",
                          }}
                        >
                          {`${t(
                            "common.regular"
                          )} ${FormatHelpers.formatMoneyLocale(
                            regularPrice,
                            lang
                          )}`}
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            fontSize: "16px",
                            letterSpacing: "-0.4px",
                            lineHeight: "1",
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                          title={descriptionLocale}
                        >
                          {descriptionLocale}
                        </Typography>
                        {hasUrl ? (
                          <Box mt="auto" sx={{ pointerEvents: "none" }}>
                            <InfoIcon>?</InfoIcon>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Paper>
                </Link>
              </SwiperSlide>
            );
          })}
        </Slideshow>
      </Box>
    </Box>
  );
}
