import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { LoadingStatus } from "../contexts/LoadingStatus";

export default function Loading({ children, loading }: { children: React.PropsWithChildren<any>; loading: LoadingStatus }) {
  if (loading === LoadingStatus.Loading)
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: "100vh" }}>
        <CircularProgress />
      </Stack>
    );

  return children;
}
