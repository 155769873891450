import { Stack, Typography } from "@mui/material";
import BackButton from "./BackButton";

type Props = {
  title: string;
  returnUrl: string;
};

export default function PageHeader(props: Props) {
  return (
    <Stack direction="row" alignItems="center" spacing={1} mb={3}>
      <BackButton url={props.returnUrl} />
      <Typography variant="h5">{props.title}</Typography>
    </Stack>
  );
}
