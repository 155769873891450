import i18n from "i18next";

const supportedLanguages = ["fr", "en"];

class AuthentificationService {
  token: string | null = null;
  language: string = "en";

  public login() {
    const query = new URLSearchParams(window.location.search);

    const queryLanguage = query.get("lang");
    if (queryLanguage) {
      if (supportedLanguages.includes(queryLanguage)) {
        console.log(`[AuthentificationService]  Language '${queryLanguage}' detected in QueryParams`);
        this.language = queryLanguage;
        localStorage.setItem("language", queryLanguage);
      } else {
        console.log(`[AuthentificationService] invalid language received '${queryLanguage}'`);
      }
    } else {
      this.language = localStorage.getItem("language") || "en";

      console.log(`[AuthentificationService]  Language '${this.language}' used from local storage | default`);
    }
    i18n.changeLanguage(this.language);

    const queryToken = query.get("token");
    if (queryToken) {
      localStorage.setItem("token", queryToken);

      const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
      console.log("[AuthentificationService]  Token detected in QueryParams, redirecting. Redirecting to", url);
      window.location.href = url;
    } else {
      this.token = localStorage.getItem("token");
    }

    if (this.token) {
      console.log("[AuthentificationService] Token found in localStorage");
    } else {
      console.log("[AuthentificationService] No Token found");
    }
  }

  public getAccessToken() {
    return this.token || undefined;
  }
}

const authentificationService = new AuthentificationService();
export default authentificationService;
