import { createContext, useContext, useEffect, useState } from "react";
import { LoadingStatus } from "./LoadingStatus";
import userService from "../services/Users/UserService";
import authentificationService from "../services/AuthentificationService";
import { Roles } from "../services/Users/Roles";
import Me from "../services/Users/Me";
import Organization from "../services/Organizations/Organization";

export interface ISessionContext {
  status: string;
  user: Me | undefined;
  role: Roles;
  hasFeature: (name: string) => boolean;
  organizationHasFeature: (organizationId: number, name: string) => boolean;
}
const defaultState = {
  status: LoadingStatus.Loading,
  user: undefined,
  role: Roles.None,
  hasFeature: (name: string) => false,
  organizationHasFeature: (organizationId: number, name: string) => false,
};

const SessionContext = createContext<ISessionContext>(defaultState);

export const useSessionContext = () => useContext(SessionContext);

type Props = {
  children?: JSX.Element;
};

function SessionProvider({ children }: Props) {
  const [user, setUser] = useState<Me | undefined>(undefined);
  const [status, setStatus] = useState<string>(LoadingStatus.Loading);

  useEffect(() => {
    async function getMe() {
      try {
        authentificationService.login();

        console.log(`API_URL=${process.env.REACT_APP_API_URL}, APIv2_URL=${process.env.REACT_APP_APIv2_URL}`);

        const me = await userService.getMe();
        setUser(me);
        console.log("me", me);
        setStatus(LoadingStatus.Success);
      } catch (e) {
        setStatus(LoadingStatus.Failure);
      }
    }

    getMe();
  }, []);

  function getRole(): Roles {
    if (!user) return Roles.None;

    if (user.roles.find((r) => r === "WorkindAdmin") != null) return Roles.WorkindAdmin;
    if (user.roles.find((r) => r === "OrganizationAdmin") != null) return Roles.OrganizationAdmin;
    return Roles.None;
  }

  function getOrganizationsWithFeature(name: string): Array<Organization> {
    if (!user) return [];

    return user.organizations.filter((o) => o.features.includes(name));
  }

  function hasFeature(name: string): boolean {
    if (!user) return false;

    const orgs = getOrganizationsWithFeature(name);

    return orgs.length > 0;
  }

  function organizationHasFeature(organizationId: number, name: string): boolean {
    if (!user) return false;

    const org = user.organizations.find((o) => o.id === organizationId);
    if (!org) return false;

    return org.features.includes(name);
  }

  return (
    <SessionContext.Provider
      value={{
        status,
        user,
        role: getRole(),
        hasFeature,
        organizationHasFeature
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export { SessionProvider };
