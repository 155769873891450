import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import reportService from "../../services/Reports/ReportService";
import { Notify } from "../../components/notify";
import { Dayjs } from "dayjs";
import DateRangeSelector from "../../components/DateRangeSelector";
import { DateHelpers } from "../../services/DateHelpers";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OrganizationSelector from "../../components/OrganizationSelector";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { useSessionContext } from "../../contexts/SessionContext";
import { DownloadHelpers } from "../../components/DownloadHelpers";

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export function TransactionReportPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [organizationSelected, setOrganizationSelected] = useState<number>(-1);
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());
  const [reportFiles, setReportFiles] = useState<Array<String>>([]);

  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  useEffect(() => {
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0].id);
      console.log(`selected first org '${user.organizations[0].id}'`);
    }
  }, [user, workindAdmin]);

  useEffect(() => {
    listReports();
  }, [dateTo, organizationSelected]);

  const handleDownloadReportFile = async (p: string) => {
    try {
      if (organizationSelected >= 0) {
        if (p.search("ReimbursementsSummary") > -1) {
          const result = await reportService.downloadReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString(), "ReimbursementsSummary");
          if (result !== undefined) {
            DownloadHelpers.downloadCsvFile(result as string, p.substring(32));
          }
        }
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  const listReports = async () => {
    try {
      if (organizationSelected >= 0) {
        const result = await reportService.listReportFile(organizationSelected, dateTo?.toISOString());
        if (result !== undefined) {
          setReportFiles(result as string[]);
        } else {
          setReportFiles([]);
          console.error(t("reports.msg-error-undefined"));
        }
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  const generateReports = async () => {
    try {
      if (organizationSelected >= 0) {
        await reportService.generateReportFile(organizationSelected, dateFrom?.toISOString(), dateTo?.toISOString());
        Notify.success(t("reports.msg-success-filegen"));
        listReports();
      }
    } catch (error) {
      Notify.error(error + "");
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("reports.label-reports")}</Typography>{" "}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={2}>
        {workindAdmin && <OrganizationSelector allowAllSelection={false} onChange={(id) => setOrganizationSelected(id)} value={organizationSelected} />}

        <DateRangeSelector
          isDisabled={false}
          onChangeFrom={(date) => {
            setDateFrom(date);
          }}
          onChangeTo={(date) => {
            setDateTo(date);
          }}
        />

        <Button variant="contained" onClick={generateReports}>
          {t("reports.btn-txt-genrepo")}
        </Button>
      </Stack>

      <Stack direction="row" flex={1}>
        <Grid item xs={12} md={12} flex={1}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            {t("reports.label-output-reports")}
          </Typography>

          <Demo>
            <List>
              {reportFiles.map((value, index) => (
                <Link
                  key={index}
                  to={""}
                  onClick={() => {
                    handleDownloadReportFile(value as string);
                  }}
                >
                  <ListItem>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={value} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Demo>
        </Grid>
      </Stack>
    </>
  );
}
