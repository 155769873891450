import { Box, Container } from "@mui/system";
import { useEffect, useState } from "react";
import theme from "../theme/main-theme";
import { ThemeProvider } from "@emotion/react";
import userService from "../services/Users/UserService";
import UserServiceDashboard from "../services/Users/UserDashboardProfile";
import AllocationComponent from "./components/AllocationComponent";
import NewsComponent from "./components/NewsComponent";
import LinksCardComponent from "./components/LinksCardComponent";
import { OrdersComponent } from "./components/OrdersComponent";
import { OrderAgainComponent } from "./components/OrderAgainComponent";
import ReimbursementsComponent from "./components/ReimbursementsComponent";
import { LoadingStatus } from "../contexts/LoadingStatus";
import { Notify } from "../components/notify";
import { useTranslation } from "react-i18next";
import LoadingComponent from "../components/LoadingComponent";
import FooterComponent from "./components/FooterComponent";

export default function UserDashboard() {
  const [state, setState] = useState<UserServiceDashboard | undefined>(undefined);
  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.None);
  const { t } = useTranslation();

  useEffect(() => {
    async function getUserDashboard() {
      try {
        setLoading(LoadingStatus.Loading);
        const result = await userService.getUserDashboard();
        if (result) {
          setState(result);
          setLoading(LoadingStatus.Success);
        }
      } catch {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("dashboard.error-loading"));
      }
    }
    getUserDashboard();
  }, [t]);

  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  if (state === undefined) return <></>;

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box padding={{ xs: "0 4px", sm: "0 20px", md: "0 40px" }}>
          <AllocationComponent state={state} />
          <NewsComponent state={state} />
          <LinksCardComponent />
          <OrdersComponent state={state} />
          <OrderAgainComponent state={state} />
          <ReimbursementsComponent state={state} />
        </Box>
      </Container>
      <FooterComponent />
    </ThemeProvider>
  );
}
