import { Typography } from "@mui/material";
import React from "react";

interface Props {
  children?: React.ReactNode;
}

export default function InfoIcon(props: Props) {
  const { children } = props;
  return (
    <Typography
      sx={{
        fontWeight: "bold",
        fontSize: "20px",
        height: 28,
        width: 28,
        backgroundColor: "#CFDD6C",
        borderRadius: "50%",
        color: "#02334a",
        textAlign: "center",
        cursor: "default",
      }}
    >
      {children ? children : 'i' }
    </Typography>
  );
}
