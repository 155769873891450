import axios from "axios";
import authentificationService from "./AuthentificationService";

export default class WorkindApiClient {
  apiUrl: string | undefined = process.env.REACT_APP_API_URL;
  apiv2Url: string | undefined = process.env.REACT_APP_APIv2_URL;
  appPortalUrl: string | undefined = process.env.REACT_APP_PORTAL_URL;

  async get<T>(url: string): Promise<T | undefined> {
    const response = await axios.get<T>(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("GET", url, error);
    });
    return response?.data;
  }

  async getBlob(url: string): Promise<Blob | undefined> {
    const { data: blob } = await axios.get<Blob>(url, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${await authentificationService.getAccessToken()}` },
    });
    return blob;
  }

  async post<Tin, Tout>(url: string, data: Tin): Promise<Tout | undefined> {
    const response = await axios.post<Tout>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("POST", url, error);
    });
    return response?.data;
  }

  async postNoResponse<Tin>(url: string, data: Tin): Promise<boolean> {
    await axios.post(url, data, await this.getHttpConfig()).catch((error) => {
      return false;
    });
    return true;
  }

  async delete<Tout>(url: string): Promise<Tout | undefined> {
    const response = await axios.delete<Tout>(url, await this.getHttpConfig()).catch((error) => {
      return this.handleError("DELETE", url, error);
    });
    return response?.data;
  }

  async put<Tin>(url: string, data: Tin): Promise<void> {
    await axios.put<Tin>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("PUT", url, error);
    });
  }

  async patch<Tin>(url: string, data: Tin): Promise<boolean> {
    const response = await axios.patch<Tin>(url, data, await this.getHttpConfig()).catch((error) => {
      return this.handleError("PATCH", url, error, false);
    });
    if (!response?.status) return false;
    const res = response.status >= 200 && response.status < 300;
    console.log("patch", response, res);
    return res;
  }

  protected async getHttpConfig() {
    return {
      headers: { Authorization: `Bearer ${await authentificationService.getAccessToken()}` },
    };
  }

  private handleError(method: string, url: string, error: any, allow404: boolean = true) {
    if (error.response) {
      if (error.response.status === 404 && allow404) {
        return undefined;
      }

      console.error(`${method} ${url} error ${error.response.status} : '${error.response.statusText}'`);
    } else if (error.message) {
      console.error(`${method} ${url} error ${error.message}`);
    } else {
      console.error(`${method} ${url} unkown error`, error);
    }

    throw error;
  }
}
