import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import cashIcon from "../../assets/images/cash.svg";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import FormatHelpers from "../../services/FormatHelpers";
import InfoIcon from "../../components/InfoIcon";

type Props = {
  state: UserServiceDashboard | undefined;
};
export default function AllocationComponent(props: Props) {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language") || "en";

  let allocationTips = undefined;
  if (props.state && props.state.organization.allocationTips)
    allocationTips = lang === "fr" ? props.state.organization.allocationTips.fr : props.state.organization.allocationTips.en;
  allocationTips = allocationTips ? allocationTips.trim() : undefined;

  return (
    <>
      <Grid container mt={9.5} mb={10} justifyContent="space-between">
        <Grid item lg={8} md={7} xs={12}>
          <Typography variant="h1">{`${t("dashboard.userPage.hello")} ${props.state?.firstName},`}</Typography>
          <Typography variant="h2" mb={4}>
            {t("dashboard.userPage.ask-label")}
          </Typography>
        </Grid>

        <Grid item lg={4} md={5} sm={12} sx={{ maxWidth: { md: "278px", xs: "100%" }, display: { md: "initial", xs: "none" } }}>
          <Paper elevation={0} sx={{ padding: "25px 34px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: 1,
                  fontWeight: "500",
                  letterSpacing: "-0.96px",
                  color: "#02334A",
                  whiteSpace: "nowrap",
                }}
              >
                {t("dashboard.userPage.allocation")}
              </Typography>

              {allocationTips && (
                <Tooltip arrow title={<Box sx={{ padding: "15px", fontSize: "14px" }}>{parse(allocationTips)}</Box>}>
                  <div>
                    <InfoIcon />
                  </div>
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: "flex", marginTop: "20px" }}>
              <Typography
                sx={{
                  fontSize: "26px",
                  fontWeight: "500",
                  color: "#02334a",
                  letterSpacing: "-0.5px",
                  minWidth: "90px",
                  marginRight: "20px",
                }}
              >
                {FormatHelpers.formatMoneyLocale(props.state?.balance, lang)}
              </Typography>
              <img src={cashIcon} alt="Cash icon" style={{ marginTop: "5px" }} />
            </Box>

            <Typography
              sx={{
                color: "#304659",
                lineHeight: "0.5",
                fontSize: "12px",
                paddingBottom: "10px",
                letterSpacing: "0",
              }}
            >
              {t("dashboard.userPage.label-available")}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
