import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

type Props = {
  onChange: (status: string) => void;
};

export default function OrderStatusSelector(props: Props) {
  const { t } = useTranslation();

  const [statusSelected, setStatusSelected] = useState<string>("All");

  const handleStatusChange = (event: SelectChangeEvent) => {
    const status = event.target.value;
    setStatusSelected(status);
    props.onChange(status);
  };

  return (
    <FormControl sx={{ minWidth: 100 }}>
      <InputLabel id="status-label">{t("common.status")}</InputLabel>
      <Select labelId="status-label" id="status-select" value={statusSelected} label={t("common.status")} onChange={handleStatusChange} autoWidth>
        <MenuItem value={"All"}>All</MenuItem>
        <MenuItem value={"new"}>{t("order.status-new")}</MenuItem>
        <MenuItem value={"cancelled"}>{t("order.status-cancelled")}</MenuItem>
        <MenuItem value={"partially_cancelled"}>{t("order.status-partiallycancelled")}</MenuItem>
        <MenuItem value={"paid"}>{t("order.status-paid")}</MenuItem>
      </Select>
    </FormControl>
  );
}
