import { Box, CardMedia, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import userService from "../services/Users/UserService";
import cashIcon from "../assets/images/cash.svg";

export default function EmptyReimbursements() {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <CardMedia component="img" src={cashIcon} sx={{ mb: "5px", width: { xs: 30, md: 40 } }} />
      <Typography
        sx={{
          fontSize: { xs: "19px", md: "27px" },
          fontWeight: "400",
          textAlign: "center",
        }}
      >
        {t("dashboard.userPage.table-nodatareimbursement")}
      </Typography>
      <Typography>
        <Link href={`${userService.appPortalUrl}/cascade-refunds`} target="_parent" color="#26A68F" letterSpacing={-0.4}>
          {t("dashboard.userPage.link-reimbursements-init")}
        </Link>
      </Typography>
    </Box>
  );
}
