import { Roles } from "./Roles";

export default class RolesHelpers {
  public static IsOrganizationAdminFromList(roles: Array<Roles>) {
    for (var role of roles) {
      if (this.IsOrganizationAdmin(role)) {
        return true;
      }
    }

    return false;
  }

  public static IsWorkindAdminFromList(roles: Array<Roles>) {
    for (var role of roles) {
      if (this.IsWorkindAdmin(role)) {
        return true;
      }
    }

    return false;
  }

  public static IsOrganizationAdmin(role: Roles) {
    return role === Roles.WorkindAdmin || role === Roles.OrganizationAdmin;
  }

  public static IsWorkindAdmin(role: Roles) {
    return role === Roles.WorkindAdmin;
  }
}
