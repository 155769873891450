import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Notify } from "./notify";
import transactionService from "../services/Transactions/TransactionService";
import { CreateTransactionType } from "../services/Transactions/CreateTransactionType";

interface Props {
  organizationId: number;
  userId: number;
  organizationWalletId: string;
  minAmount: number;
  open: boolean;
  onClose: () => void;
  onTransactionCreated: () => void;
}

export default function CreateUserTransactionDialog(props: Props) {
  const { t } = useTranslation();

  const [type, setType] = useState<string>("0");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);

  const create = async () => {
    const typedType: CreateTransactionType = CreateTransactionType[type as keyof typeof CreateTransactionType];

    if (await transactionService.createTransaction(props.organizationId, props.userId, props.organizationWalletId, typedType, description, amount)) {
      Notify.success(t("create-user-transaction.create-transaction-success"));
    } else {
      Notify.error(t("create-user-transaction.create-transaction-error"));
    }

    props.onTransactionCreated();
    props.onClose();
  };

  const close = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={close}>
      <DialogTitle>{t("create-user-transaction.title")}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <DialogContentText>{t("create-user-transaction.explanations")}</DialogContentText>
        </Box>

        <FormControl fullWidth>
          <InputLabel>{t("create-user-transaction.type")}</InputLabel>
          <Select
            value={type.toString()}
            label={t("create-user-transaction.type")}
            onChange={(event: SelectChangeEvent) => {
              setType(event.target.value);
            }}
          >
            <MenuItem value={CreateTransactionType.Adjustment}>{t("transaction-type.2")}</MenuItem>
            <MenuItem value={CreateTransactionType.Contribution}>{t("transaction-type.1")}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          autoFocus
          margin="dense"
          label={t("create-user-transaction.description")}
          type="text"
          fullWidth
          variant="standard"
          value={description}
          onChange={(e: any) => {
            setDescription(e.target.value);
          }}
        />

        <TextField
          margin="dense"
          label={t("create-user-transaction.amount")}
          type="text"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(e: any) => {
            const value = e.target.value;
            if (/^-?\d*\.?\d*$/.test(value)) {
              setAmount(value);
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{t("common.cancel")}</Button>
        <Button onClick={create} disabled={description.length <= 0 || amount < props.minAmount || amount === 0}>
          {t("common.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
