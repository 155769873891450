import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

type Props = {
  value: string;
  onChange: (status: string) => void;
};

function UserStatusSelector(props: Props) {
  const { t } = useTranslation();

  const dataSource = [
    { label: t("user.field-all"), value: "All" },
    { label: t("user.field-actif"), value: "Actif" },
    { label: t("user.field-inactif"), value: "Inactif" },
  ];

  return (
    <>
      <Box sx={{ minWidth: 150, minHeight: 50 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-label">{t("user.field-status")}</InputLabel>
          <Select value={props.value} label={t("user.field-status")} onChange={(e) => props.onChange(e.target.value)}>
            {dataSource.map((key) => (
              <MenuItem key={key.value} value={key.value}>
                {key.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
}

export default UserStatusSelector;
