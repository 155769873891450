import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import PrimaryText from './typography/primary-text';
import typography from './typography/typography';

//https://mui.com/customization/default-theme/

const lightTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 750,
      lg: 1200,
      xl: 1900,
    },
  },
  palette: {
    mode: 'light',
    background: {
      default: '#F7F7F7',
      custom: '#E1E9F0',
      light: '#E8EDF2',
    },
    primary: {
      main: '#00334A',
    },
    primaryText: {
      main: PrimaryText.main,
      800: PrimaryText[800],
      600: PrimaryText[600],
      400: PrimaryText[400],
      200: PrimaryText[200],
      100: PrimaryText[100],
    },
    secondary: blueGrey,
    contrastThreshold: 5,
    tonalOffset: 0.2,
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#fff',
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b',
      contrastText: '#fff',
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100',
      contrastText: '#fff',
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828',
      contrastText: '#fff',
    },
  },
  typography: {
    ...typography,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: '15px',
          },
        },
      },
    },
  },
});

export default lightTheme;
