import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { DateHelpers } from "../services/DateHelpers";

type Props = {
  onChangeFrom: (date: Dayjs | null) => void;
  onChangeTo: (date: Dayjs | null) => void;
  isDisabled: boolean;
};

export default function DateRangeSelector(props: Props) {
  const { t } = useTranslation();

  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());

  const handleDateFromChange = (date: Dayjs | null) => {
    setDateFrom(date);
    props.onChangeFrom(date);
  };

  const handleDateToChange = (date: Dayjs | null) => {
    setDateTo(date);
    props.onChangeTo(date);
  };

  return (
    <>
      <DatePicker value={dateFrom} onChange={handleDateFromChange} disabled={props.isDisabled} />
      <Typography color="inherit" noWrap>
        {t("common.to")}
      </Typography>
      <DatePicker value={dateTo} onChange={handleDateToChange} disabled={props.isDisabled} />
    </>
  );
}
