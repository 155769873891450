import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import CreateUserTransactionDialog from "../components/CreateUserTransactionDialog";
import OrganizationFeature from "../services/Organizations/OrganizationFeature";
import TransactionWithAccount from "../services/Transactions/TransactionWithAccount";
import User from "../services/Users/User";
import { useSessionContext } from "../contexts/SessionContext";

interface Props {
  user: User;
  organizationId: number;
  organizationWalletId: string;
  transactions: Array<TransactionWithAccount>;
  onTransactionCreated: () => void;
}

export default function UserTransactionsBlockWallet(props: Props) {
  const { t } = useTranslation();
  const { user, organizationHasFeature } = useSessionContext();

  const [createUserTransactionOpen, setCreateUserTransactionOpen] = useState(false);

  const useWallet = user && organizationHasFeature(props.user.organizationId, OrganizationFeature.UseAccountWallet);

  const userWallet = props.user.wallets.find((w) => w.organizationWalletId === props.organizationWalletId);
  const balance = useWallet ? userWallet?.balance : props?.user.balance;

  return (
    <>
      <Box>
        <Button variant="contained" sx={{ float: "right" }} onClick={() => setCreateUserTransactionOpen(true)}>
          {t("admin.employee-profile.createTransaction")}
        </Button>

        <CreateUserTransactionDialog
          organizationId={props.organizationId}
          userId={props.user.id}
          organizationWalletId={props.organizationWalletId}
          minAmount={-balance!}
          open={createUserTransactionOpen}
          onClose={() => {
            setCreateUserTransactionOpen(false);
          }}
          onTransactionCreated={onTransactionCreated}
        />

        <Stack direction="row" spacing={1} mb={3}>
          <Typography>{t("admin.employee-profile.balance")}</Typography>
          <Typography>{`${balance!.toFixed(2)} $`}</Typography>
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("common.id")}</TableCell>
              <TableCell align="center">{t("common.date")}</TableCell>
              <TableCell align="center">{t("common.type")}</TableCell>
              <TableCell>{t("admin.employee-profile.fromAccount")}</TableCell>
              <TableCell>{t("admin.employee-profile.toAccount")}</TableCell>
              <TableCell>{t("admin.employee-profile.description")}</TableCell>
              <TableCell align="right">{t("admin.employee-profile.amount")}</TableCell>
              <TableCell align="right">{t("admin.employee-profile.balance")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.transactions.map((transaction) => (
              <TableRow key={transaction.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{transaction.id}</TableCell>
                <TableCell align="center">{transaction.date ? transaction.date.format("YYYY/MM/DD") : ""}</TableCell>
                <TableCell align="center">{t(`transaction-type.${transaction.type.toString()}`)}</TableCell>
                <TableCell>{transaction.fromAccountId === props.user.accountId ? "" : transaction.fromAccountName}</TableCell>
                <TableCell>{transaction.toAccountId === props.user.accountId ? "" : transaction.toAccountName}</TableCell>
                <TableCell>{transaction.description}</TableCell>
                <TableCell align="right" sx={{ minWidth: 100 }}>
                  {transaction.amount.toFixed(2)} $
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 100 }}>
                  {transaction.balance.toFixed(2)} $
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  async function onTransactionCreated() {
    props.onTransactionCreated();
  }
}
