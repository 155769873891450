import { Box, SvgIconOwnProps, Tooltip } from "@mui/material";
import { Circle, Info } from "@mui/icons-material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Reimboursement from "../services/Reimbursement/Reimbursement";

interface StatusContentProps {
  request: Reimboursement;
}

export default function ReimbursmentStatusContent(props: StatusContentProps) {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language") || "en";
  const { request } = props;
  const { status, reimbursementRequestRejectReasonText: reasonText = null } =
    request;

  const statusColorMap: SvgIconOwnProps["color"][] = useMemo(
    () => ["warning", "success", "error", "success"],
    []
  );

  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap">
      <span style={{ marginRight: "10px" }}>
        {t("dashboard.userPage.label-" + status)}
      </span>
      <Circle color={statusColorMap[status]} style={{ marginLeft: "auto" }} />
      {status === 2 && reasonText !== null ? (
        <Tooltip
          arrow
          title={
            <Box sx={{ padding: "15px", fontSize: "14px" }}>
              {`${t("dashboard.userPage.table-reject-reason")}: ${
                lang === "fr" ? reasonText.fr : reasonText.en
              }`}
            </Box>
          }
        >
          <Info />
        </Tooltip>
      ) : null}
    </Box>
  );
}
