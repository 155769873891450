export default class FormatHelpers {
  public static nthNumber = (number: number): string => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  public static formatInt(value: any) {
    if (value && typeof value === "number") {
      return value.toLocaleString("en", { maximumFractionDigits: 0 });
    }
    return "0";
  }

  public static formatMoney(value: any) {
    if (value && typeof value === "number") {
      return value.toLocaleString("en", { minimumFractionDigits: 2 }) + " $";
    }
    return "0.00 $";
  }

  public static formatMoneyLocale(value: any, lang: string) {
    const decimalSeparator = lang === "fr" ? "." : ",";

    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      decimalSeparator: decimalSeparator,
    };

    if (!value && value !== 0) {
      value = 0;
    }

    let str = value.toLocaleString(lang, options);

    return lang === "fr" ? `${str} $` : `$${str}`;
  }

  public static formatMoneyInt(value: any) {
    if (value && typeof value === "number") {
      return value.toLocaleString("en", { maximumFractionDigits: 0 }) + " $";
    }
    return "0 $";
  }

  public static formatDateTimeShort(value: any) {
    if (!value) return "";
    return `${value.getFullYear()}/${(value.getMonth() + 1).toString().padStart(2, "0")}/${value.getDate().toString().padStart(2, "0")} ${value
      .getHours()
      .toString()
      .padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`;
  }

  public static formatDateShort(value: any) {
    if (!value) return "";
    return `${value.getFullYear()}/${(value.getMonth() + 1).toString().padStart(2, "0")}/${value.getDate().toString().padStart(2, "0")}`;
  }

  public static formatDateShortDescriptive(date: Date) {
    const currentYear = new Date().getFullYear();
    return date.toLocaleDateString("en-US", { day: "numeric", month: "short", year: currentYear === date.getFullYear() ? undefined : "numeric" });
  }

  public static formatDateShortDescriptiveFr(date: Date) {
    const currentYear = new Date().getFullYear();
    return date.toLocaleDateString("fr-CA", { day: "numeric", month: "short", year: currentYear === date.getFullYear() ? undefined : "numeric" });
  }

  public static formatDateDescriptive(date: Date) {
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}${FormatHelpers.nthNumber(day)}, ${year}`;
  }

  public static formatDateDescriptiveFr(date: Date) {
    return date.toLocaleDateString("fr-CA", { day: "numeric", month: "long", year: "numeric" });
  }

  public static formatDateSlash(date: Date) {
    return `${date.getFullYear()} / ${(date.getMonth() + 1).toString().padStart(2, "0")} / ${date.getDate().toString().padStart(2, "0")}`;
  }

  public static formatDateSlashFr(date: Date) {
    return `${date.getDate().toString().padStart(2, "0")} / ${(date.getMonth() + 1).toString().padStart(2, "0")} / ${date.getFullYear()}`;
  }
}
