import { Paper, Typography } from "@mui/material";

interface Props {
  title: string;
  children: React.PropsWithChildren<any>;
}

export default function PageSection({ title, children }: Props) {
  return (
    <Paper elevation={2} sx={{ marginBottom: "20px", padding: "15px 15px" }}>
      <Typography component="h1" variant="h5" sx={{ marginBottom: "18px !important" }}>
        {title}
      </Typography>

      {children}
    </Paper>
  );
}
