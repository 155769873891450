import { useEffect, useState } from "react";
import { Notify } from "../../components/notify";
import orderService from "../../services/Orders/OrderService";
import OrderDetailled from "../../services/Orders/OrderDetailled";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import LoadingComponent from "../../components/LoadingComponent";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { Dayjs } from "dayjs";
import FormatHelpers from "../../services/FormatHelpers";
import { DateHelpers } from "../../services/DateHelpers";
import OrganizationSelector from "../../components/OrganizationSelector";
import OrderStatusSelector from "../../components/OrderStatusSelector";
import DateRangeSelector from "../../components/DateRangeSelector";
import Unauthorized from "../../components/Unauthorized";

export default function OrdersPage() {
  const { t } = useTranslation();
  const { user } = useSessionContext();

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [orders, setOrders] = useState<Array<OrderDetailled>>([]);
  const [sourceOrders, setSourceOrders] = useState<Array<OrderDetailled>>([]);

  const [organizationSelected, setOrganizationSelected] = useState<number>(-1);
  const [statusSelected, setStatusSelected] = useState<string>("All");
  const [dateFrom, setDateFrom] = useState<Dayjs | null>(DateHelpers.nowDateOnly().add(-30, "day"));
  const [dateTo, setDateTo] = useState<Dayjs | null>(DateHelpers.nowDateOnly());

  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  useEffect(() => {
    if (user && !workindAdmin && user.organizations.length > 0) {
      setOrganizationSelected(user.organizations[0].id);
      console.log(`selected first org '${user.organizations[0].id}'`);
    }
  }, [user, workindAdmin]);

  useEffect(() => {
    async function get() {
      try {
        if (user) {
          if (organizationSelected >= 0 || workindAdmin) {
            let orgId: number = organizationSelected;
            if (!RolesHelpers.IsOrganizationAdminFromList(user.roles)) {
              orgId = user.organizations[0].id;
            }

            const orders = await orderService.getOrders(orgId, dateFrom?.toISOString(), dateTo?.toISOString());
            console.log(`OrdersPage.useEffet(orgId=${orgId}, from=${dateFrom?.toISOString()}, to=${dateTo?.toISOString()}) --> ${orders.length}`);
            //console.log("orders", orders);
            setSourceOrders(orders);
            setLoading(LoadingStatus.Success);
          }
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        Notify.error(t("admin.orders.error-loading"));
      }
    }

    get();
  }, [user, organizationSelected, dateFrom, dateTo]);

  //Filter orders
  useEffect(() => {
    if (statusSelected === "All") {
      setOrders(sourceOrders);
    } else {
      const result = sourceOrders.filter((o) => o.status === statusSelected);
      setOrders(result);
    }
  }, [sourceOrders, statusSelected]);

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", type: "string" },
    {
      field: "date",
      headerName: "Date",
      type: "dateTime",
      minWidth: 140,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatDateTimeShort(value);
      },
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      valueFormatter: ({ value }) => {
        return t("order.status-" + value);
      },
    },
    {
      field: "name",
      headerName: t("common.user"),
      type: "string",
      minWidth: 140,
      valueGetter: ({ row }) => {
        return row.firstname + (row.lastname ? " " + row.lastname : "");
      },
      flex: 1,
    },
    { field: "email", headerName: t("common.email"), type: "string", minWidth: 250, flex: 1 },
    { field: "organizationName", headerName: t("common.organization"), type: "string", minWidth: 250, flex: 1 },
    {
      field: "total",
      headerName: t("admin.orders.total"),
      type: "string",
      minWidth: 140,
      disableExport: true,
      flex: 1,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatMoney(value);
      },
    },
    {
      field: "creditUsed",
      headerName: t("admin.orders.credit-used"),
      type: "string",
      minWidth: 140,
      disableExport: true,
      valueFormatter: ({ value }) => {
        return FormatHelpers.formatMoney(value);
      },
      flex: 1,
    },
    {
      field: "total_raw",
      flex: 1,
      align: "center",
      minWidth: 140,
      headerName: t("admin.orders.total"),
      type: "string",
      valueGetter: ({ row }) => {
        return row.total;
      },
    },
    {
      field: "creditused_raw",
      flex: 1,
      align: "left",
      headerName: t("admin.orders.credit-used"),
      type: "number",
      valueGetter: ({ row }) => {
        return row.creditUsed;
      },
    },
  ];

  const total = orders.length === 0 ? 0 : orders.map((item) => item.total).reduce((prev, next) => prev + next);
  const totalCreditUsed = orders.length === 0 ? 0 : orders.map((item) => item.creditUsed).reduce((prev, next) => prev + next);

  if (!workindAdmin) {
    return <Unauthorized />;
  }
  if (loading === LoadingStatus.Loading) {
    return <LoadingComponent />;
  }

  function CustomToolbar() {
    let fileName = "orders";
    if (dateFrom && dateTo) {
      fileName = `orders_${dateFrom.format("YYYY-MM-DD")}-${dateTo.format("YYYY-MM-DD")}`;
    }

    return (
      <GridToolbarContainer>
        {/* <GridToolbarFilterButton /> */}
        <GridToolbarQuickFilter />
        <GridCsvExportMenuItem
          options={{
            fileName: fileName,
            fields: ["id", "date", "status", "name", "email", "organizationName", "total_raw", "creditused_raw", "accountbalance_raw"],
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1} mb={3}>
        <Typography variant="h2">{t("admin.orders.title")}</Typography>{" "}
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        {workindAdmin && <OrganizationSelector allowAllSelection={true} onChange={(id) => setOrganizationSelected(id)} value={organizationSelected} />}
        <OrderStatusSelector onChange={(status) => setStatusSelected(status)} />
        <DateRangeSelector
          isDisabled={false}
          onChangeFrom={(date) => {
            setDateFrom(date);
          }}
          onChangeTo={(date) => {
            setDateTo(date);
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" spacing={3} my={3}>
        <Typography color="inherit">{t("admin.orders.total")}:</Typography>
        <Typography color="inherit" sx={{ fontWeight: "bold" }}>
          {FormatHelpers.formatMoney(total)}
        </Typography>
        <Typography color="inherit">{t("admin.orders.credit-used")}:</Typography>
        <Typography color="inherit" sx={{ fontWeight: "bold" }}>
          {FormatHelpers.formatMoney(totalCreditUsed)}
        </Typography>
      </Stack>

      <DataGrid
        rows={orders}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              total_raw: false,
              creditused_raw: false,
              accountbalance_raw: false,
            },
          },
          pagination: { paginationModel: { pageSize: 25 } },
        }}
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        localeText={{
          toolbarQuickFilterPlaceholder: t("common.search"),
          toolbarExport: t("common.export"),
          toolbarExportCSV: t("common.datagrid-downloadcsv"),
          toolbarExportPrint: t("common.datagrid-print"),
          noRowsLabel: t("common.datagrid-norows"),
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => t("common.datagrid-pagination-count", { from, to, count }),
          },
        }}
      />
    </>
  );
}
