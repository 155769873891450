import React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowForwardIos } from "@mui/icons-material";

import userService from "../../services/Users/UserService";
import UserServiceDashboard from "../../services/Users/UserDashboardProfile";
import FormatHelpers from "../../services/FormatHelpers";
import ReimbursmentStatusContent from "../../components/ReimbursmentStatusContent";
import EmptyReimbursements from "../../components/EmptyReimbursment";
import clipIcon from "../../assets/images/clip.svg";
import ReimbursmentProofContent from "../../components/ReimbursmentProofContent";

type Props = {
  state: UserServiceDashboard | undefined;
};

export default function ReimbursementsComponent({ state }: Props) {
  const { reimbursementRequest = [] } = state || {};
  const { length: reimbursementRequestLength } = reimbursementRequest;
  const lang = localStorage.getItem("language") || "en";
  const { t } = useTranslation();
  const theme = useTheme();
  const isUnderMediumWidth = useMediaQuery(theme.breakpoints.down("md"));

  const tableHeadCellsStyle = useMemo(
    () => ({
      color: "white",
      fontSize: "16px",
      fontFamily: "GT Walsheim",
      fontWeight: "500",
    }),
    []
  );

  const hasReimbursements = reimbursementRequestLength > 0;

  return (
    <Box mb={6}>
      <Typography variant="h3">
        {t("dashboard.userPage.latest-reimbursements-label")}
      </Typography>
      {isUnderMediumWidth ? (
        <>
          {!hasReimbursements ? (
            <Paper
              elevation={0}
              sx={{
                padding: { xs: "65px 15px", md: "34px 15px", marginBottom: 6 },
              }}
            >
              <EmptyReimbursements />
            </Paper>
          ) : (
            reimbursementRequest.map((request) => {
              const {
                id,
                categoryImageUrl,
                categoryName,
                itemName,
                creationDate,
                proofs,
              } = request;
              return (
                <Paper elevation={0} key={id} sx={{ padding: "20px", mb: 3 }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    pb={2}
                    mb={2}
                    borderBottom="1px solid rgba(112,112,112, 0.4)"
                  >
                    <img
                      src={categoryImageUrl}
                      alt={lang === "fr" ? categoryName.fr : categoryName.en}
                      loading="lazy"
                      width={45}
                      height={45}
                    />
                    <Box ml={3}>
                      <Typography
                        sx={{
                          fontSize: "11px",
                          lineHeight: 1,
                          letterSpacing: 0,
                          color: "#26A68F",
                        }}
                        mb="5px"
                      >
                        {lang === "fr" ? categoryName.fr : categoryName.en}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "19px",
                          lineHeight: 0.9,
                          letterSpacing: "-0.47px",
                          color: "#000000",
                          fontWeight: 500,
                        }}
                        mb="5px"
                      >
                        {lang === "fr" ? itemName.fr : itemName.en}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "20px",
                          letterSpacing: "-0.4px",
                          color: "#000000",
                        }}
                      >
                        {lang === "fr" &&
                          FormatHelpers.formatDateSlashFr(
                            new Date(creationDate)
                          )}
                        {lang === "en" &&
                          FormatHelpers.formatDateSlash(new Date(creationDate))}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      { proofs.map(proof => <ReimbursmentProofContent key={proof.proofId} proof={proof} />) }                      
                    </Box>
                    <Box ml="auto">
                      <ReimbursmentStatusContent request={request} />
                    </Box>
                  </Box>
                </Paper>
              );
            })
          )}
        </>
      ) : (
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            border: "1px solid rgba(112,112,112, 0.4)",
          }}
        >
          <Table sx={{ border: "none" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={tableHeadCellsStyle} width="17%">
                  {t("dashboard.userPage.table-category")}
                </TableCell>
                <TableCell align="center" sx={tableHeadCellsStyle}>
                  {t("dashboard.userPage.table-items")}
                </TableCell>
                <TableCell align="left" sx={tableHeadCellsStyle} width="15%">
                  {t("dashboard.userPage.table-date")}
                </TableCell>
                <TableCell align="center" sx={tableHeadCellsStyle} width="25%">
                  <img
                    src={clipIcon}
                    alt="Clip icon"
                    style={{ marginRight: "10px", verticalAlign: "middle" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    {t("dashboard.userPage.table-proofs")}
                  </span>
                </TableCell>
                <TableCell align="left" sx={tableHeadCellsStyle} width="15%">
                  {t("dashboard.userPage.table-status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {hasReimbursements ? (
                reimbursementRequest.map((request) => {
                  const {
                    id,
                    categoryName,
                    itemName,
                    categoryImageUrl,
                    creationDate,
                    proofs,
                  } = request;
                  return (
                    <TableRow
                      key={id}
                      sx={{
                        "&:nth-of-type(even) td": { backgroundColor: "#FFF" },
                      }}
                    >
                      <TableCell
                        align="center" /* sx={{ borderTopLeftRadius: 17, borderBottomLeftRadius: 17 }} */
                      >
                        <Tooltip
                          arrow
                          title={
                            lang === "fr" ? categoryName.fr : categoryName.en
                          }
                        >
                          <img
                            src={categoryImageUrl}
                            alt={
                              lang === "fr" ? categoryName.fr : categoryName.en
                            }
                            loading="lazy"
                            width={45}
                            height={45}
                          />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        <Box>{lang === "fr" ? itemName.fr : itemName.en}</Box>
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: "left" }}>
                        <Box pr="15px" whiteSpace="nowrap">
                          <span
                            title={FormatHelpers.formatDateTimeShort(
                              new Date(creationDate)
                            )}
                          >
                            {lang === "fr" &&
                              FormatHelpers.formatDateDescriptiveFr(
                                new Date(creationDate)
                              )}
                            {lang === "en" &&
                              FormatHelpers.formatDateDescriptive(
                                new Date(creationDate)
                              )}
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                      { proofs.map(proof => <ReimbursmentProofContent key={proof.proofId} proof={proof} />) }      
                      </TableCell>
                      <TableCell align="center" sx={{ textAlign: "left" }}>
                        <ReimbursmentStatusContent request={request} />
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ backgroundColor: "#FFF", padding: "55px 0" }}
                  >
                    <EmptyReimbursements />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {hasReimbursements ? (
        <Box textAlign="right" mt={{ xs: 4, md: 6 }}>
          <Button
            variant="contained"
            color="success"
            href={`${userService.appPortalUrl}/cascade-refunds`}
            target="_parent"
            sx={{
              textTransform: "none",
              borderRadius: "10px",
              fontSize: "16px",
              lineHeight: "23px",
              letterSpacing: "-0.54px",
              padding: "12px 10px 12px 20px",
              "&:hover": {
                backgroundColor: "#26A68F",
              },
            }}
          >
            <span style={{ marginRight: "10px" }}>
              {t(
                isUnderMediumWidth
                  ? "common.view-all"
                  : "dashboard.userPage.link-reimbursements-details"
              )}
            </span>
            <ArrowForwardIos />
          </Button>
        </Box>
      ) : null}
    </Box>
  );
}
