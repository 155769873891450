import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

export default function LoadingComponent() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 1, height: "100vh" }}>
      <CircularProgress />
    </Stack>
  );
}
