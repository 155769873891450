import { useMemo } from "react";
import { Grid, Paper, Typography, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";

import userService from "../../services/Users/UserService";

export default function LinksCardComponent() {
  const { t } = useTranslation();

  const cards = useMemo(
    () => [
      {
        title: t("dashboard.userPage.title-label1"),
        subtitle: t("dashboard.userPage.header-label1"),
        body: t("dashboard.userPage.parag-label1"),
        link: `${userService.appPortalUrl}/cascade-refunds`,
      },
      {
        title: t("dashboard.userPage.title-label2"),
        subtitle: t("dashboard.userPage.header-label2"),
        body: t("dashboard.userPage.parag-label2"),
        link: `${userService.appPortalUrl}/home`,
      },
    ],
    [t]
  );

  return (
    <Grid container columnSpacing={5} rowSpacing={4} mb={9}>
      {cards.map(({ title, subtitle, body, link }, index) => (
        <Grid key={`card-${index}`} item xs={12} md={6}>
          <Paper elevation={0} sx={{ padding: { xs: "28px", md: "28px 42px" }, height: "100%" }}>
            <Grid container spacing={5} sx={{ marginBottom: "12px" }}>
              <Grid item xs={9} md={10}>
                <Grid item xs={12} md={9}>
                  <Typography
                    sx={{
                      fontSize: { xs: "19px", md: "27px" },
                      lineHeight: { xs: "22px", md: "32px" },
                      letterSpacing: { xs: "-0.57px", md: "-0.8px" },
                      paddingTop: "12px",
                      marginBottom: "10px",
                      color: "#00334A",
                      fontWeight: "500",
                      textWrap: "pretty",
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} md={2} textAlign="right">
                <Button
                  href={link}
                  target="_parent"
                  sx={{
                    backgroundColor: "#00BFA6",
                    borderRadius: "50%",
                    width: 46,
                    height: 46,
                    minWidth: 46,
                    marginTop: "12px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#00BFA6",
                    },
                  }}
                >
                  <ArrowForwardIosIcon color="inherit" sx={{ height: 24, width: 24 }} />
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={11} md={8}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    letterSpacing: "-0.4px",
                    color: "#000",
                    marginBottom: "20px",
                    textWrap: "pretty",
                  }}
                >
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mb={2}>
              <Grid item xs={12} md={10}>
                <Typography
                  sx={{
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "-0.33px",
                    color: "#a1a1aa",
                    whiteSpace: "unset",
                    marginTop: { md: "10px", sm: "0px", xs: "0px" },
                    textWrap: "pretty",
                  }}
                >
                  {body}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
