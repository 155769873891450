import { ReactNode, useCallback, useRef, useState } from "react";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Swiper } from "swiper/react";
import SwiperEl from "swiper";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import "swiper/css";

type Props = {
  spaceBetween: number;
  slidesPerView: number | "auto";
  totalSlides: number;
  loop?: boolean;
  children?: ReactNode;
  onInit?: (swiperEl: SwiperEl) => void;
  onChange?: (index: number) => void;
};

export default function Slideshow({ spaceBetween, slidesPerView, totalSlides, loop, children, onInit, onChange }: Props) {
  const [slideIndex, setSlideIndex] = useState(0);
  const swiperRef = useRef<SwiperEl>();

  const hasMultipleSlides = totalSlides > 1;
  const hasNotEnoughSlides = slidesPerView !== "auto" && totalSlides <= slidesPerView;

  const isPrevDisabled = !loop && slidesPerView !== "auto" && slideIndex === 0;
  const isNextDisabled = !loop && slidesPerView !== "auto" && (slideIndex === totalSlides - 1 || swiperRef.current?.isEnd);

  const theme = useTheme();
  const isUnderMediumWidth = useMediaQuery(theme.breakpoints.down("md"));
  const isUnderSmallWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const onSwiperInit = useCallback(
    (swiper: SwiperEl) => {
      swiperRef.current = swiper;
      if (onInit) {
        onInit(swiper);
      }
    },
    [onInit]
  );

  const onSwiperSlideChange = useCallback(
    (swiper: SwiperEl) => {
      const newIndex = swiper.realIndex;
      setSlideIndex(newIndex);
      if (onChange) {
        onChange(newIndex);
      }
    },
    [onChange]
  );

  return (
    <Box position="relative">
      {hasMultipleSlides ? (
        <Box
          sx={{
            position: "relative",
            margin: { xs: "0 -20px", sm: "0 -44px", md: "0 -20px" },
            paddingBottom: "10px",
          }}
        >
          <Swiper
            spaceBetween={spaceBetween}
            slidesPerView={slidesPerView}
            loop={loop}
            onSwiper={onSwiperInit}
            onSlideChange={onSwiperSlideChange}
            style={{ padding: isUnderMediumWidth && !isUnderSmallWidth ? "0px 44px" : "0px 20px" }}
          >
            {children}
          </Swiper>
          {!hasNotEnoughSlides ? (
            <>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: { md: "-43px", lg: "-50px" },
                  marginTop: "-20px",
                  display: { xs: "none", md: "inline-flex" },
                  pointerEvents: isPrevDisabled ? "none" : "auto",
                  opacity: isPrevDisabled ? 0.25 : 1,
                }}
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <ArrowBackIosNew />
              </IconButton>
              <IconButton
                sx={{
                  position: "absolute",
                  top: "50%",
                  right: { md: "-43px", lg: "-50px" },
                  marginTop: "-20px",
                  display: { xs: "none", md: "inline-flex" },
                  pointerEvents: isNextDisabled ? "none" : "auto",
                  opacity: isNextDisabled ? 0.25 : 1,
                }}
                onClick={() => swiperRef.current?.slideNext()}
              >
                <ArrowForwardIos />
              </IconButton>
            </>
          ) : null}
        </Box>
      ) : (
        children
      )}

      {!hasNotEnoughSlides ? (
        <Box textAlign="center" position="absolute" bottom="-28px" width="100%">
          {[...Array(totalSlides)].map((_, index) => (
            <button
              type="button"
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                border: "1px solid #707070",
                padding: 0,
                margin: "1.5px",
                cursor: "pointer",
                backgroundColor: index === slideIndex ? "#26A68F" : "transparent",
              }}
              key={`bullet-${index}`}
              onClick={() => {
                swiperRef.current?.slideToLoop(index);
              }}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
}
