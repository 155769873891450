import { blueGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import PrimaryText from "./typography/primary-text";
import "./../assets/sass/style.css";

const breakpoints = {
  values: {
    xs: 0,
    sm: 500,
    md: 1024,
    lg: 1360,
    xl: 1900,
  },
};

const theme = createTheme({
  breakpoints,
  typography: {
    fontFamily: "GT Walsheim, Poppins, sans-serif",
    h1: {
      fontSize: "51px",
      fontWeight: 500,
      letterSpacing: "-1px",
      lineHeight: 1,
      color: "#00334a",
      fontFamily: "GT Walsheim, Poppins, sans-serif",
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: "46px",
        letterSpacing: "-1.38px",
      },
    },
    h2: {
      fontSize: "45px",
      letterSpacing: "-1.35px",
      lineHeight: "51px",
      color: "#B4B4B5",
      fontWeight: 400,
      fontFamily: "GT Walsheim, Poppins, sans-serif",
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: "26px",
        lineHeight: "30px",
        letterSpacing: "-0.78px",
        marginTop: "10px",
      },
    },
    h3: {
      fontWeight: 400,
      color: "#000",
      fontSize: "30px",
      letterSpacing: "-.04rem",
      lineHeight: "1",
      marginBottom: "34px",
      [`@media screen and (max-width: ${breakpoints.values.md}px)`]: {
        fontSize: "22px",
        letterSpacing: "-0.55px",
      },
    },
    h4: {
      fontWeight: 500,
      color: "#00334A",
      fontSize: "20px",
      lineHeight: "25px",
      letterSpacing: "-0.6px",
    },
    h5: {
      color: "rgb(0, 51, 74)",
      fontWeight: "300",
      fontSize: "24px",
    },
    h6: {
      fontFamily: "Poppins",
    },
    body1: {
      letterSpacing: "-0.4px",
    },
  },
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "#05334A",
          color: "#fff",

          th: {
            padding: "16px",
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "10px 16px",
          fontSize: "14.4px",
          fontFamily: "GT Walsheim",
          fontWeight: "400",
          border: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: "rgb(0 0 0 / 70%)",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "white",
          borderRadius: "17px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          backgroundColor: "#26A68F",
          borderRadius: "10px",
          font: 'normal normal normal 16px/17px GT Walsheim',
          color: "#FFFFFF",
          letterSpacing: '-0.4px',
          height: "48px",
          opacity: '1'
        },
      },
    },
    MuiTextField: {
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#F7F7F7",
      custom: "#E1E9F0",
      light: "#E8EDF2",
    },
    primary: {
      main: "#00334A",
    },
    primaryText: {
      main: PrimaryText.main,
      800: PrimaryText[800],
      600: PrimaryText[600],
      400: PrimaryText[400],
      200: PrimaryText[200],
      100: PrimaryText[100],
    },
    secondary: blueGrey,
    contrastThreshold: 5,
    tonalOffset: 0.2,
    success: {
      main: "#26A68F", // #2e7d32
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
    info: {
      main: "#0288d1",
      light: "#03a9f4",
      dark: "#01579b",
      contrastText: "#fff",
    },
    warning: {
      main: "#D2DD78", // #ed6c02
      light: "#ff9800",
      dark: "#e65100",
      contrastText: "#fff",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
      contrastText: "#fff",
    },
  },
});

export default theme;
