import { useTranslation } from "react-i18next";
import { Box, Container, Grid, Typography, Button, CardMedia } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import userService from "../../services/Users/UserService";

export default function FooterComponent() {
  const { t } = useTranslation();
  return (
    <Box pt={{ xs: 5, md: 10 }} overflow="hidden">
      <Box sx={{ background: "#26A68F", height: { xs: 250, md: 300 } }}>
        <Container maxWidth="lg" style={{ height: "100%" }}>
          <Box display="flex" alignItems="center" padding={{ xs: "0 4px", sm: "0 20px", md: "0 40px" }} height="100%">
            <Grid container alignItems="center" sx={{ width: { xs: "48%", md: "60%" } }}>
              <Grid item xs={12} md={4}>
                <Typography
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "39px", md: "5.5rem" },
                    letterSpacing: { xs: "-1.17px", md: "-2.64px" },
                    lineHeight: { xs: "1", md: "1.5" },
                    fontWeight: "500",
                    color: "white",
                  }}
                >
                  {t("common.faq")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={8} sx={{ pr: { xs: 0, md: 2 } }}>
                <Typography
                  sx={{
                    fontSize: { xs: "21px", md: "1.5rem" },
                    fontWeight: "500",
                    lineHeight: "1.8rem",
                    color: "white",
                    letterSpacing: { xs: "-0.5px", md: "-0.4px" },
                  }}
                >
                  {t("common.questions")}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "5px",
                    lineHeight: { xs: 1.2, md: "1.25rem" },
                    color: "white",
                    fontWeight: "400",
                    fontSize: { xs: "14px", md: "16px" },
                    width: "90%",
                  }}
                >
                  {t("common.questionFaq")}
                </Typography>
              </Grid>
            </Grid>
            <Button
              href={`${userService.appPortalUrl}/faq`}
              target="_parent"
              sx={{
                backgroundColor: "#05334A",
                borderRadius: "50%",
                minWidth: { xs: 40, md: 46 },
                height: { xs: 40, md: 46 },
                color: "white",
                "&:hover": {
                  backgroundColor: "#05334A",
                },
              }}
            >
              <ArrowForwardIosIcon color="inherit" sx={{ height: 24, width: 24 }} />
            </Button>
            <Box flexGrow="1" position="relative" height="100%">
              <CardMedia
                component="img"
                sx={{
                  position: "absolute",
                  right: { xs: "-145px", md: "-25px" },
                  bottom: 0,
                  width: "auto",
                  height: { xs: "106%", md: "120%" },
                }}
                src="https://workindstorageprod.blob.core.windows.net/workind/production/global/capu.png"
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
