import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import analyticsService from "../../services/Analytics/AnalyticsService";
import { useSessionContext } from "../../contexts/SessionContext";
import RolesHelpers from "../../services/Users/RolesHelpers";
import { LoadingStatus } from "../../contexts/LoadingStatus";
import AnalyticsKeyedValue from "../../services/Analytics/AnalyticsKeyedValue";
import FormatHelpers from "../../services/FormatHelpers";
import DashboardCard from "./DashboardCard";
import { Grid } from "@mui/material";

export default function ReimbursementRequestsSummary() {
  const { t } = useTranslation();
  const { user } = useSessionContext();
  const workindAdmin = user && RolesHelpers.IsWorkindAdminFromList(user.roles);

  const [loading, setLoading] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [keyedValues, setKeyedValues] = useState<Array<AnalyticsKeyedValue>>([]);

  useEffect(() => {
    async function get() {
      try {
        if (user && workindAdmin) {
          setLoading(LoadingStatus.Loading);

          const result = await analyticsService.getReimbursementRequestsSummary();
          setKeyedValues(result || []);

          setLoading(user ? LoadingStatus.Success : LoadingStatus.Failure);
        }
      } catch (e) {
        setLoading(LoadingStatus.Failure);
        setErrorMessage(t("dashboard.error-loading"));
      }
    }

    get();
  }, [t, user, workindAdmin]);

  const total = keyedValues.reduce((accumulator, o) => accumulator + o.value, 0);
  const count = keyedValues.reduce((accumulator, o) => accumulator + o.count, 0);
  const average = total / (count > 0 ? count : 1);

  return (
    <DashboardCard title={t("dashboard.reimbursementrequests-summary.title")} loading={loading} error={errorMessage}>
      <Typography component="p" variant="h4">
        {FormatHelpers.formatMoneyInt(total)}
      </Typography>

      <Grid container mt={4}>
        <Grid item>
          <Typography variant="h6">{FormatHelpers.formatInt(count)}</Typography>
        </Grid>
        <Grid item flex={1} ml={1} color="text.secondary" sx={{ paddingTop: "5px" }}>
          {t("dashboard.reimbursementrequests-summary.title-count")}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Typography variant="h6" flex={1}>
            {FormatHelpers.formatMoneyInt(average)}
          </Typography>
        </Grid>
        <Grid item flex={1} ml={1} color="text.secondary" sx={{ paddingTop: "5px" }}>
          {t("dashboard.reimbursementrequests-summary.title-average")}
        </Grid>
      </Grid>
    </DashboardCard>
  );
}
